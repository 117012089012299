import config from 'modules-core/config'
import log from 'modules-core/log'

const logger = log.getLogger('urlSystem')

type SocketPath =
| 'ws-master-interface'
| 'ws-online-player/websocket'
| 'ws-control-panel'
| 'ws-web-player/websocket'

export const createUrlSystem = () => {
	const maybePort = (port: string) => port ? `:${port}` : ''
	const maybeSubdomain = (subdomain: string) => subdomain ? `${subdomain}.` : ''
	return {
		// Replace local hostnames. We might have inconsistent URLs in redis if the site
		// has been accessed at multiple locations.
		replaceLocalDomainAndPort: (path: string) => {
			const new_path = path.replace(RegExp('://[^/]*(localhost|lvh.me)[^/]+'), `://${config.httpHostname}${maybePort(config.httpPort)}`)
			if (new_path !== path)
				logger.debug(`replaceLocalDomainAndPort(): ${path} => ${new_path}`)
			return new_path
		},

		createUrlHttp: (path: string, subdomain: string = '') => {
			return `${config.httpProtocol}://${maybeSubdomain(subdomain)}${config.httpHostname}${maybePort(config.httpPort)}/${path}`
		},

		createUrlWs: (socketPath: SocketPath) => {
			return `${config.wsProtocol}://${config.wsHostname}${maybePort(config.wsPort)}/${socketPath}`
		},
	}
}

export type iUrlSystem = ReturnType<typeof createUrlSystem>

export const urlSystem = createUrlSystem()
export default urlSystem
