import { iAudioSystem } from 'modules-core/audioSystem/audioSystem'
import { Element } from 'modules-core/elementSystem/types'
import { createElementSpawnSystem } from 'modules-core/elementSystem/elementSpawnSystem'
import { iSampleSystem, createSampleSystem } from 'modules-core/sampleSystem/sampleSystem'
import { createUuidSystem, iUuidSystem } from 'modules-core/uuidSystem/uuidSystem'
import { createEvent, createMutableMap } from 'modules-core/utility'
import { iAuthSystem } from 'modules-core/authSystem'
import { createPlaylistSystem } from './playlistSystem'
import { createOneshotSystem } from './oneshotSystem'
import { createElementVolumeSystem } from './elementVolumeSystem'
import { filterByElementId } from './utility'
import { ParseExt } from 'modules-core/utility'
import { fromLength } from 'modules-core/utility/MathExt'
import { UnitySocket } from 'modules-core/socket/types'
import getServerTime from 'modules-core/utility/serverTime'
import { eventSystem } from 'modules-core/eventSystem'
import { Sample } from 'modules-core/sampleSystem/types'

// Register events.
eventSystem.add('startElementSample')
eventSystem.add('stopElement')

interface iArgs {
	authSystem: iAuthSystem
	audioSystem: iAudioSystem
	sampleSystem?: iSampleSystem
	uuidSystem?: iUuidSystem
}

export const createElementSystem = ({ authSystem, audioSystem, sampleSystem, uuidSystem }: iArgs) => {
	uuidSystem = uuidSystem || createUuidSystem()
	sampleSystem = sampleSystem || createSampleSystem({ audioSystem, uuidSystem, })

	const elementMap = createMutableMap<string, Element.Instance>() as Element.MutableMap

	const elementVolumeSystem = createElementVolumeSystem({ elementMap })
	const oneshotSystem = createOneshotSystem({ audioSystem })

	const playlistSystem = createPlaylistSystem()
	const elementSpawnSystem = createElementSpawnSystem({
		authSystem, audioSystem, sampleSystem,
		uuidSystem, elementMap, playlistSystem,
		oneshotSystem, elementVolumeSystem,
	})

	const system = {
		...elementSpawnSystem,
		...elementVolumeSystem,
		playlistSystem,
		oneshotSystem,
		getElements: () => elementMap.getRaw(),
		map: elementMap,
		stopElementByUuid: (uuid: string) =>
			elementMap.mutate(map => map.get(uuid).dispose()),
		getElementsWithElementId:(elementId: number)=>
			filterByElementId(elementMap, elementId)
			// Only elements that have samples that were not manually triggered.
			.filter(([_, element]) =>
				element.playlistData.sampleDataList.some(sample => !sample.manuallyTriggered)
			),
		getStopWait: (elementId: number) => {
			// Get the wait time until the current sample will stop.
			let now = getServerTime()
			let stopTime: number
			system.getElementsWithElementId(elementId).forEach(([_, element]) => {
				let sample = element.playlistData.sampleDataList.find(
					(s) => s.startTime <= now && s.startTime + s.duration > now
				)
				if (sample && (!stopTime || sample.startTime + sample.duration < stopTime)) {
					stopTime = sample.startTime + sample.duration
				}
			})
			// TODO: Use the server provided fadeout duration instead of a 3000ms hard limit.
			return Math.min(Math.max(stopTime - now, 0), 3000) || 0
		},
		stopElementByElementId: (elementId: number, emit: boolean = true) => {
			filterByElementId(elementMap, elementId)
				.forEach(([, element]) => element.dispose())
			if (emit) {
				eventSystem.dispatch('stopElement', {
					elementId,
					timeToStop: system.getStopWait(elementId)
				})
			}
		},
		stopAllElements: () => {
			let seen: Set<number> = new Set()
			return Promise.all([...elementMap.getRaw()].map(([, element]) => {
				if (!seen.has(element.elementId)) {
					seen.add(element.elementId)
					eventSystem.dispatch('stopElement', {
						elementId: element.elementId,
						timeToStop: system.getStopWait(element.elementId),
					})
				}
				return element.dispose()
			}))
		},
		setOneshotVolume: (value: number) =>
			oneshotSystem.setVolume(value),
		playElement: (element: UnitySocket.Element) =>
			system.spawnElementFromParams(ParseExt.socketElementToParams(element)),
		forEachElement: (func: (e: Element.Instance) => void) =>
			elementMap.get().forEach(func),

		createSocketElementSnapshot: async (element: UnitySocket.Element, repeatLength = 3) => {
			const firstParams = ParseExt.socketElementToParams(element)
			const elementInstances = await system.createParamsSnapshot(firstParams, repeatLength)
			const snapshot = {
				timestamp: getServerTime(),
				elementInstances,
				elementMessage: element,
			}
			return snapshot
		},
		createParamsSnapshot: async (params: Element.Params, repeatLength = 3) => {
			let prevInstance = await elementSpawnSystem.paramsToData(params)
			const instances = [prevInstance]
			for (let i = 0; i < repeatLength; i++) {
				const nextParams = elementSpawnSystem.dataToRepeatParams(prevInstance)
				prevInstance = await elementSpawnSystem.paramsToData(nextParams)
				instances.push(prevInstance)
			}
			return instances
		},
	}

	document.addEventListener('syrinscape.internalStartElementSample', (event: CustomEvent) => {
		let sampleData = event.detail

		// Get time to stop.
		let timeToStop = sampleData.startTime + sampleData.duration - getServerTime()

		// Get time to stop or next sample.
		let nextStartTime: number
		system.getElementsWithElementId(sampleData.elementId).forEach(([_, element]) => {
			let nextSample = element.playlistData.sampleDataList.find(
				(s) => s.startTime > sampleData.startTime && s.uuid !== sampleData.uuid
			)
			if (nextSample && (!nextStartTime || nextSample.startTime < nextStartTime)) {
				nextStartTime = nextSample.startTime
			}
		})
		sampleData.timeToStopOrNextSample = nextStartTime
			? nextStartTime - getServerTime()  // Next sample
			: timeToStop  // Stop

		eventSystem.dispatch('startSample', {
			elementId: sampleData.elementId,
			playlistEntryId: sampleData.playlistEntryId,
			timeToStop,
			timeToStopOrNextSample: sampleData.timeToStopOrNextSample,
		})
	})

	return system
}

export type iElementSystem = ReturnType<typeof createElementSystem>
