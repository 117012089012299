import { SocketAPI } from 'modules-core/socket'
import {Element} from 'modules-core/elementSystem'
import { Sample } from 'modules-core/sampleSystem'

export const elementChunksToData = (elements:SocketAPI.Server.ElementMap = {})=>
	Object.entries(elements)
		.flatMap(([elementId,element])=>
			Object.entries(element.chunks || {})
				.map(([uuid,chunk]):Element.Data=>({
					uuid,  // Chunk UUID from server data
					crossfade: element.crossfade,
					volume: element.volume,
					elementId: Number(elementId),
					type: element.type,
					title:element.title,
					playlistData:{
						sampleDataList:chunk.map(sampleToData)
					},
					presetName:element.reverb,
				})))

export const elementChunksToUuids = (elements:SocketAPI.Server.ElementMap = {})=>
	Object.values(elements)
		.flatMap(element=>
			Object.keys(element.chunks))

export const elementChunksToElementIds = (elements:SocketAPI.Server.ElementMap = {}) =>
	Object.keys(elements)
		.map((elementId) => parseInt(elementId, 10))

type SampleToData = (sample:SocketAPI.Server.Sample)=>Sample.Data
export const sampleToData:SampleToData = sample=>({
	uuid:sample.uuid,
	name:sample.name,
	sampleId:sample.id,
	volume:sample.volume,
	startTime:sample.start * 1000,
	duration:(sample.end - sample.start) * 1000,
	presetName:sample.reverb,
	spatializable:!!sample.position,
	spatialData:sample.position && {
		position:tupleToVector3(sample.position),
		velocity:tupleToVector3(sample.velocity)
	},
	url:sample.url,
	playlistEntryId:sample.playlist_entry_pk,
	manuallyTriggered:!!sample.manually_triggered,
})

const tupleToVector3 = (vec:[number,number,number])=>
	({x:vec[0],y:vec[1],z:vec[2]})
