/*
Create a global 'syrinscape' object to expose our public API and hold references to any
singletons to ensure they are instantiated only once, even if loaded multiple times by
independent bundles that can optionally be used together.
*/

declare global {
	interface Window {
		syrinscape: any
		Sentry: any
	}
}

// Create a proxy object to hold references to non-public singletons, to break tab
// completion in the console. This is more about keeping our public API clean than
// making anything truly inaccessible.
const createRegistry = () => {
	const registry = {}
	return new Proxy({}, {
		get(target, p, receiver) {
			return registry[p as string]
		},
		set(target, p, newValue, receiver) {
			registry[p as string] = newValue
			return true
		},
	})
}

window.syrinscape ??= { _r: createRegistry() }

export const syrinscape = window.syrinscape
export default syrinscape
