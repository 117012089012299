import { AnimationCurve } from 'modules-core/audioSystem/types'
import log from 'modules-core/log'
import { add, length, iVector3, MathExt, multiply } from 'modules-core/utility'



export const minSafeAudioNumber = 0.001
export const maxSafeAudioNumber = 1 - minSafeAudioNumber

const logger = log.getLogger('audioEffectSystem')

const lerp = (a, b, t) => a + (b - a) * t

const curveMap: Record<AnimationCurve, (val: number) => number> = {
	linear: (t: number) => t,
	easeIn: (t: number) => 1 - Math.cos(t * Math.PI * 0.5),//start gentle, equal power
	easeOut: (t: number) => Math.sin(t * Math.PI * 0.5),//start steep, default
}


//OPTIMIZE ME
//GAH a bug! exponentialRamp jumps to value, not sure why...
// node.gain.linearRampToValueAtTime(target, time)
// node.gain.exponentialRampToValueAtTime(target, time)
// node.gain.setTargetAtTime(normalizeVal(val), context.currentTime + duration, duration * 0.3)


export const rampToValueAtTime = (param: AudioParam, val: number, start: number, duration: number, curveName?: AnimationCurve) => {
	curveName ??= 'easeOut'
	if (duration === 0)
		duration = minSafeAudioNumber
	const values = []
	const current = param.value
	const resolution = 32

	const curve = curveMap[curveName]
	for (let i = 0; i < resolution; i++) {
		const t = curve(i / (resolution - 1))
		values.push(lerp(current, val, t))
	}
	param.cancelScheduledValues(start)
	try {
		param.setValueCurveAtTime(values, start, duration)
	} catch (error) {
		if (/AudioParam.setValueCurveAtTime: Can't add events during a curve event/.test(error.message)) {
			logger.error(error)
		} else {
			throw error
		}
	}
}


export const rampToCenteredVelocityOverTime = (zPos: AudioParam, startPos: iVector3, velocity: iVector3, startTime: number, duration: number, resolution = 10) => {
	const values = new Array<number>(resolution)
	for (let i = 0; i < resolution; i++) {
		const iFrac = i / (resolution - 1)
		const timeStep = duration * iFrac

		const pos = add(startPos, multiply(velocity, timeStep))

		values[i] = length(pos)
	}
	zPos.setValueCurveAtTime(values, startTime, duration)
	return values
}
