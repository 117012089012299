// import getServerTime from 'modules-core/utility/serverTime'
import { MathExt, wait } from 'modules-core/utility'
import { createAudioEffect } from '.'
import { createDefaultEffectParams } from '../constants'
import { AudioEffect } from '../types'
import { maxSafeAudioNumber, minSafeAudioNumber, rampToValueAtTime } from '../utility'
import config from 'modules-core/config'

export const createVolumeEffect = (params: AudioEffect.VolumeEffectParams = {}) => {
	params = Object.assign(createDefaultEffectParams('volume'), params)
	if (params.isDestination)
		params.output = undefined

	const node = new GainNode(config.audioContext, { gain: params.volume })

	// const normalizeVal = (val: number) => MathExt.clamp(val, minSafeAudioNumber, maxSafeAudioNumber)

	// Range (in dB) for volume slider, from 0-100%.
	// Chris claims that applying a 50% reduction to global volume is necessary to get an
	// element set to 1% to be as quiet as he needs it with a range of 30. Changing it to
	// 45 makes an element at 1% with global volume at 100% sound exactly like Chris would
	// have heard when he tested. Ben has tested lots of content and prefers 37.5dB range.
	// With the current range of 30dB, every 20% on the slider is -9dB, which is said to
	// sound about half as loud.
	const query = new URLSearchParams(location.search)
	const volumeSliderRange = Number(query.get('dbRangeVolumeEffect') ?? 37.5)

	// Gain 1 is "reference level" (dBFS) and the maximum we should ever send to the audio
	// destination. Here we convert volume expressed as a percentage to dBFS (min: -30dB),
	// and then to gain, to give us an exponential volume control that behaves more like
	// we expect. Every 20% reduction on the slider will halve the volume (-6dBFS).
	const volumeToGain = (volume: number) => {
		const dbfs = -volumeSliderRange + (volume * volumeSliderRange)
		if (dbfs == -volumeSliderRange) {
			return 0
		} else {
			// See: https://teropa.info/blog/2016/08/30/amplitude-and-loudness.html
			return Math.pow(10, dbfs / 20)
		}
	}

	// Convert gain back to volume expressed as a percentage
	const gainToVolume = (gain: number) => {
		const dbfs = 20 * Math.log10(gain)
		return 1 - (dbfs / -volumeSliderRange)
	}

	const effect: AudioEffect.VolumeEffect = {
		params,
		...createAudioEffect(node),
		get value() { return gainToVolume(node.gain.value) },
		set value(val) {
			node.gain.cancelScheduledValues(config.audioContext.currentTime)
			node.gain.value = volumeToGain(val)
		},
		fadeToValue: async (val, durationSecs = params.fadeDuration, curveName) => {
			rampToValueAtTime(
				node.gain,
				volumeToGain(val),
				config.audioContext.currentTime,
				durationSecs,
				curveName,
			)
			await wait(durationSecs * 1000)
		},
		setParam: {
			'volume': (val) => node.gain.value = val,
			'fadeDuration': val => params.fadeDuration = val
		},
		// fadeToValueAtTimestamp: async (val: number, timestamp: number) =>{
		//	 const duration =
		//	 rampSafely(val, context.currentTime + timestamp - MathExt.millisToSecs(getServerTime())),
		//	 await wait(durationSecs * 1000)
		// }
	}
	return effect
}

