import getServerTime from 'modules-core/utility/serverTime'
import { createDefaultEffectParams } from './constants'
import { caveConvolverUrl, concertHallConvolverUrl, stairwellConvolverUrl } from './convolverUrls'
import { AudioEffect } from './types'



export const createDefaultEffectFile = (): AudioEffect.EffectFile => ({
	"effects": [
		{
			"name": "Alley ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/0vz8w5byepxv8k4/Sweep%20samples_Alley2nd%20try_dc.wav?dl=0"
		},
		{
			"fftSize": 256,
			"isDestination": true,
			"maxDecibels": -30,
			"minDecibels": -100,
			"name": "Analyser",
			"smoothingTimeConstant": 0.8,
			"type": "analyser"
		},
		{
			"name": "Arena ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/ov18hhpdpe98yyr/Sweep%20samples_Arena_dc.wav?dl=0"
		},
		{
			"name": "Auditorium ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/5oqio2hvvag7sou/Sweep%20samples_Auditorium_dc.wav?dl=0"
		},
		{
			"name": "Bathroom ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/x32s6651nwnyvbb/BathroomSweep-96000-24-M-10.0s_dc.zwav.wav?dl=0"
		},
		{
			"name": "Carpetted Hallway ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/f0zbljvpkl9iaba/Sweep%20samples_Carpetted%20Hallway_dc.wav?dl=0"
		},
		{
			"name": "Cave",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/fmv48mbfvagf9gk/Room_capture_Cave_01_dc.wav?dl=0"
		},
		{
			"name": "Cave ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/ey33w5dnkdklz7d/Sweep%20samples_Cave_dc.wav?dl=0"
		},
		{
			"name": "City ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/fds6qyegx4dmcum/Room_capture_City_02_dc.wav?dl=0"
		},
		{
			"attack": 0.003,
			"knee": 30,
			"name": "Compressor",
			"output": "Analyser",
			"ratio": 12,
			"release": 0.25,
			"threshold": -24,
			"type": "compressor"
		},
		{
			"name": "Concert Hall",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/cz4rpyxx4dqdacp/Room_capture_Concert%20hall_03_dc.wav?dl=0"
		},
		{
			"name": "Concert Hall ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/22vly35tb4e5nsp/Sweep%20samples_Concert%20Hall_dc.wav?dl=0"
		},
		{
			"name": "Crazed ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/32i3gbl2ac5ixra/Sweep%20samples_Crazed_dc.wav?dl=0"
		},
		{
			"name": "Dazed ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/f4betvklj5uk6e8/Sweep%20samples_Dazed_dc.wav?dl=0"
		},
		{
			"dryOutput": "Global Volume",
			"name": "Dry Long",
			"output": "Concert Hall",
			"type": "send",
			"wetness": 0.5
		},
		{
			"dryOutput": "Global Volume",
			"name": "Dry Short",
			"output": "Concert Hall",
			"type": "send",
			"wetness": 0.2
		},
		{
			"name": "Flooded Cavern ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/q5k8hfimlka8u3m/Sweep%20samples_Flooded%20Cavern_dc.wav?dl=0"
		},
		{
			"name": "Forest ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/3sh05lsoffimjsb/Room_capture_Forest_05_fix_dc.wav?dl=0"
		},
		{
			"fadeDuration": 1,
			"name": "Global Volume",
			"output": "Local Volume",
			"type": "volume",
			"volume": 1
		},
		{
			"name": "Hallway ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/fkk8zxram928imu/Room_capture_Hallway_05_dc.wav?dl=0"
		},
		{
			"name": "Hanger ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/cafxgkmm38nq9n3/Sweep%20samples_Hanger_dc.wav?dl=0"
		},
		{
			"fadeDuration": 1,
			"name": "Local Volume",
			"output": "Compressor",
			"type": "volume",
			"volume": 1
		},
		{
			"dampening": 6000,
			"gain": 0.2,
			"hpFrequency": 600,
			"hpResonance": 0.5,
			"lpFrequency": 2000,
			"lpResonance": 0.5,
			"name": "Long Reverb",
			"output": "Global Volume",
			"preDelay": 0.01,
			"roomSize": 0.9,
			"type": "freeverb"
		},
		{
			"name": "Metal Room ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/8df9jkybo5j5j8t/Room_capture_Metal%20room_06_dc.wav?dl=0"
		},
		{
			"name": "Mountains ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/b8hgw5uimhb9efv/Room_capture_Mountains_07_dc.wav?dl=0"
		},
		{
			"name": "Padded Cell ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/oqrk3ied4b71yiy/Sweep%20samples_Padded%20Cell_dc.wav?dl=0"
		},
		{
			"name": "Parking lot ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/qpuxz6ujbgxumeu/Room_capture_Parking%20lot_08_dc.wav?dl=0"
		},
		{
			"name": "Plains ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/x9r8r4cyyuaja7d/Room_capture_Plain_09_dc.wav?dl=0"
		},
		{
			"name": "Poisoned ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/vrlkoo9xnc294ao/Sweep%20samples_Poisoned_dc.wav?dl=0"
		},
		{
			"name": "Quarry ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/zw2hfyk7reichz9/Sweep%20samples_Quarry_dc.wav?dl=0"
		},
		{
			"name": "Sewer pipe ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/ltj5zfi1avlmovp/Room_capture_Sewer_10_dc.wav?dl=0"
		},
		{
			"duration": 0.2,
			"falloff": 0.4,
			"name": "Simple Delay",
			"output": "Global Volume",
			"type": "delay"
		},
		{
			"name": "Soft furnished room ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/bf8b9jy0jhbnqft/Sweep%20samples_Soft%20furnished%20room_dc.wav?dl=0"
		},
		{
			"name": "Stairwell",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/sjzu2078lg4bpwh/3000CStreetGarageStairwell.wav?dl=0"
		},
		{
			"name": "Stone Corridor ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/c8ky9offetjl7ld/Sweep%20samples_Stone%20corridor_dc.wav?dl=0"
		},
		{
			"name": "Stone Dungeon ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/vrhznwahzoc2sno/Room_capture_Stone%20Dungeon_11_dc.wav?dl=0"
		},
		{
			"name": "Stone room ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/u3zxtfmav475pyu/Sweep%20samples_Stone%20room_dc.wav?dl=0"
		},
		{
			"name": "Through a heavy door ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/3iru4ke6t90t8o2/Sweep%20samples_Through%20a%20heavy%20door_dc.wav?dl=0"
		},
		{
			"name": "Through a light door ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/opbkl2qp9foncm4/Sweep%20samples_Through%20a%20light%20door_dc.wav?dl=0"
		},
		{
			"name": "Underwater ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/wqd1hhrscm717s2/Room_capture_Underwater_12_dc.wav?dl=0"
		},
		{
			"name": "Vanilla room ss",
			"output": "Global Volume",
			"type": "convolver",
			"url": "https://dl.dropboxusercontent.com/s/51px3ofee71ob75/Sweep%20samples_Vanila%20room_dc.wav?dl=0"
		},
		{
			"dryOutput": "Global Volume",
			"name": "Wet Effect",
			"output": "Simple Delay",
			"type": "send",
			"wetness": 0.7
		},
		{
			"dryOutput": "Global Volume",
			"name": "Wet Long",
			"output": "Long Reverb",
			"type": "send",
			"wetness": 1
		},
		{
			"dryOutput": "Global Volume",
			"name": "Wet Short",
			"output": "Stairwell",
			"type": "send",
			"wetness": 0.75
		}
	],
	"presets": {
		"Alley": {
			"dryGain": 2,
			"drySpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 30,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 1,
				"speed": 0,
				"type": "spatial"
			},
			"effect": "Alley ss",
			"name": "Alley",
			"wetSpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 50,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 0.5,
				"speed": 0,
				"type": "spatial"
			}
		},
		"Arena": {
			"dryGain": 1,
			"drySpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 1,
				"speed": 0,
				"type": "spatial"
			},
			"effect": "Arena ss",
			"name": "Arena",
			"wetGain": 0.1,
			"wetSpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 0.4,
				"speed": 0,
				"type": "spatial"
			}
		},
		"Auditorium": {
			"dryGain": 1,
			"effect": "Auditorium ss",
			"name": "Auditorium",
			"wetGain": 0.1
		},
		"Bathroom": {
			"effect": "Bathroom ss",
			"name": "Bathroom"
		},
		"Carpetted Hallway": {
			"dryGain": 1,
			"effect": "Carpetted Hallway ss",
			"name": "Carpetted Hallway",
			"wetGain": 0.4
		},
		"Cave": {
			"effect": "Cave",
			"name": "Cave"
		},
		"City": {
			"effect": "City ss",
			"name": "City"
		},
		"Concert Hall": {
			"dryGain": 0.5,
			"effect": "Concert Hall ss",
			"name": "Concert Hall",
			"wetGain": 1
		},
		"Crazed": {
			"dryGain": 1,
			"effect": "Crazed ss",
			"name": "Crazed",
			"wetGain": 0.3
		},
		"Dazed": {
			"dryGain": 1,
			"effect": "Dazed ss",
			"name": "Dazed",
			"wetGain": 0.1
		},
		"Default": {
			"dryGain": 1,
			"drySpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "My spatial effect 2",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 1,
				"speed": 0,
				"type": "spatial"
			},
			"effect": "Global Volume",
			"isFixed": true,
			"name": "Default",
			"wetGain": 1,
			"wetSpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "My spatial effect 1",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 0.4,
				"speed": 0,
				"type": "spatial"
			}
		},
		"Flooded Cavern": {
			"dryGain": 1,
			"effect": "Flooded Cavern ss",
			"name": "Flooded Cavern",
			"wetGain": 0.5
		},
		"Forest": {
			"effect": "Forest ss",
			"name": "Forest"
		},
		"Hallway": {
			"effect": "Hallway ss",
			"name": "Hallway"
		},
		"Hangar": {
			"dryGain": 1,
			"effect": "Hanger ss",
			"name": "Hangar",
			"wetGain": 0.15,
			"wetSpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 0.8,
				"speed": 0,
				"type": "spatial"
			}
		},
		"Metal Room": {
			"effect": "Metal Room ss",
			"name": "Metal Room"
		},
		"Mountains": {
			"effect": "Mountains ss",
			"name": "Mountains",
			"wetSpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 0.8,
				"speed": 0,
				"type": "spatial"
			}
		},
		"Off": {
			"effect": "Global Volume",
			"name": "Off"
		},
		"Padded Cell": {
			"dryGain": 1,
			"effect": "Padded Cell ss",
			"name": "Padded Cell",
			"wetGain": 0.2
		},
		"Parking lot": {
			"dryGain": 1,
			"effect": "Parking lot ss",
			"name": "Parking lot",
			"wetGain": 0.3
		},
		"Plain": {
			"effect": "Plains ss",
			"name": "Plain"
		},
		"Poisoned": {
			"dryGain": 1,
			"effect": "Poisoned ss",
			"name": "Poisoned",
			"wetGain": 0.5,
			"wetSpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 0.5,
				"speed": 0,
				"type": "spatial"
			}
		},
		"Quarry": {
			"effect": "Quarry ss",
			"name": "Quarry"
		},
		"Sewer pipe": {
			"effect": "Sewer pipe ss",
			"name": "Sewer pipe"
		},
		"Soft furnished room": {
			"effect": "Soft furnished room ss",
			"name": "Soft furnished room"
		},
		"Stone Corridor": {
			"dryGain": 1,
			"effect": "Stone Corridor ss",
			"name": "Stone Corridor",
			"wetGain": 0.3
		},
		"Stone Dungeon": {
			"effect": "Stone Dungeon ss",
			"name": "Stone Dungeon",
			"wetGain": 6.33
		},
		"Stone room": {
			"dryGain": 1,
			"effect": "Stone room ss",
			"name": "Stone room",
			"wetGain": 0.4
		},
		"Through a heavy door": {
			"dryGain": 0,
			"effect": "Through a heavy door ss",
			"name": "Through a heavy door",
			"wetGain": 0.5,
			"wetSpatializer": {
				"distanceModel": "exponential",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 0.7,
				"speed": 0,
				"type": "spatial"
			}
		},
		"Through a light door": {
			"dryGain": 0.1,
			"effect": "Through a light door ss",
			"name": "Through a light door",
			"wetGain": 0.47,
			"wetSpatializer": {
				"distanceModel": "inverse",
				"maxDistance": 100,
				"minDistance": 1,
				"name": "Wet Spatializer",
				"output": "Global Volume",
				"panningModel": "HRTF",
				"position": {
					"x": 0,
					"y": 0,
					"z": 0
				},
				"rolloff": 0.7,
				"speed": 0,
				"type": "spatial"
			}
		},
		"Underwater": {
			"dryGain": 1,
			"effect": "Underwater ss",
			"name": "Underwater",
			"wetGain": 0.3
		},
		"Vanilla room": {
			"dryGain": 1,
			"effect": "Vanilla room ss",
			"name": "Vanilla room",
			"wetGain": 0.5
		}
	},
	"timestamp": 1672719228481.26
})
