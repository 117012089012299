import { AudioEffect } from 'modules-core/audioEffectSystem'
import { iEvent } from 'modules-core/utility'

export const createAsyncAudioSource = (source: iAudioSource) => {

	let isReady = false
	let shouldPlay = false
	let cachedCurrentTime = 0
	const asyncSrc = {
		...source,
		handleReady: () => {
			isReady = true
			if (shouldPlay)
				source.play(cachedCurrentTime)
		},
		play: (currentTime?: number) => {
			shouldPlay = true
			cachedCurrentTime = currentTime
			if (isReady)
				source.play(currentTime)
		}
	}
	return asyncSrc
}

export type AnimationCurve = 'easeIn' | 'easeOut' | 'linear'

export interface FadeOptions {
	fadeIn?: number
	fadeOut?: number
	fadeScrub?: number
	fadeStop?: number
}

export interface iAudioSource {
	isAlive: boolean
	play: (currentTime?: number) => void
	stop: () => Promise<void>
	onPlay: iEvent<void>
	onDispose: iEvent<void>
}

export interface iCreateAudioSource {
	(src: string, output: AudioEffect.HasOutput): iAudioSource
}

export interface iCreateSourceInstantiatorArgs {
	context: AudioContext
}

export interface iSourceInstantiator {
	createAudioSource: iCreateAudioSource
}

export interface iCreateSourceInstantiator {
	(args: iCreateSourceInstantiatorArgs): iSourceInstantiator
}