import { createAudioEffect } from './createEffect'
import { createDefaultEffectParams } from '../constants'
import { AudioEffect } from '../types'
import config from 'modules-core/config'

export const createFilterEffect = (params: AudioEffect.FilterEffectParams) => {
	params = Object.assign(createDefaultEffectParams('filter'), params)

	//TODO input & output gains so we can have many ins and outs

	const node = config.audioContext.createBiquadFilter()
	node.type = params.filterType
	node.Q.value = params.resonance
	node.detune.value = params.detune
	node.gain.value = params.gain
	node.frequency.value = params.frequency

	const input = config.audioContext.createGain()
	const output = config.audioContext.createGain()

	input.connect(node)
	node.connect(output)
	const effect = {
		params,
		...createAudioEffect(input, output),
		setParam: {
			'filterType': val => node.type = val,
			'resonance': val => node.Q.value = val,
			'detune': val => node.detune.value = val,
			'gain': val => node.gain.value = val,
			'frequency': val => node.frequency.value = val,
		}
	} as AudioEffect.FilterEffect
	return effect
}

