import $ from 'jquery'
import 'app/common/dumbStubs'
import { createAudioSystem } from 'modules-core/audioSystem'
import { createAuthSystem } from 'modules-core/authSystem'
import { eventSystem } from './eventSystem'
import { postMessageSystem } from './postMessageSystem'
import { ControlSystem } from 'modules-core/controlSystem'
import { createElementSystem } from 'modules-core/elementSystem'
import { createSampleSystem } from 'modules-core/sampleSystem'
import { SocketSystem } from 'modules-core/socket'
import { SyncSystem } from 'modules-core/syncSystem'
import { createUrlSystem } from 'modules-core/urlSystem'
import { createUuidSystem } from 'modules-core/uuidSystem'
import config from 'modules-core/config'
import log from 'modules-core/log'
import syrinscape from 'app/common/syrinscape'

// Register events.
eventSystem.add('playerActive')
eventSystem.add('playerInactive')

const logger = log.getLogger('player')

interface PlayerInitOpts {
	context?: string
	configure?: () => void
	initialConfig?: {}
	onActive?: () => void
	onInactive?: () => void
	selector?: string
}

export const createPlayer = () => {
	logger.info('createPlayer()')
	const urlSystem = createUrlSystem()
	const socketSystem = SocketSystem({ urlSystem })
	const audioSystem = createAudioSystem({ urlSystem })
	const audioEffectSystem = audioSystem.effectSystem
	const authSystem = createAuthSystem({ urlSystem })
	const controlSystem = ControlSystem({ authSystem, socketSystem })
	const uuidSystem = createUuidSystem()
	const sampleSystem = createSampleSystem({ audioSystem, uuidSystem })
	const elementSystem = createElementSystem({ authSystem, audioSystem, sampleSystem, uuidSystem })
	const syncSystem = SyncSystem({ audioSystem, elementSystem, sampleSystem, socketSystem })
	const start = async () => {
		await audioSystem.init()
		audioSystem.setLocalVolume(config.localVolume)
		await socketSystem.connect()
	}
	return {
		postMessageSystem,
		urlSystem,
		socketSystem,
		audioSystem,
		audioEffectSystem,
		controlSystem,
		sampleSystem,
		elementSystem,
		syncSystem,
		init: async ({
			configure,
			context = 'default',
			initialConfig = {},
			onActive,
			onInactive,
			selector = 'body',
		}: PlayerInitOpts = {}) => {
			let deferred: HTMLElement[] = []
			// Populate config from storage and set defaults.
			await config.init(context, initialConfig)
			// User configuration.
			if (typeof configure === 'function') {
				await configure()
			}
			// Start and execute onActive.
			const activate = async () => {
				await start()
				logger.info('Player is active.')
				// Start visualisation loop.
				syrinscape.visualisation?.init()
			}
			// No interaction required. Activate immediately.
			if (config.audioContext.state !== 'suspended') {
				await activate()
				// Execute callback.
				if (typeof onActive === 'function') {
					onActive()
					eventSystem.dispatch('playerActive')
				}
			}
			// Configure unlock on interaction, with deferred clicks.
			else {
				let locked = true
				logger.warn('Player is inactive.')
				if (typeof onInactive === 'function') {
					onInactive()
					eventSystem.dispatch('playerInactive')
				}
				const unlock = async (event) => {
					if (event.target.closest(selector) === null) {
						return
					}
					// Only unlock on primary click.
					if (event.button && event.button !== 0) {
						return
					}
					// Defer this event until activation.
					deferred.push(event.target)
					event.preventDefault()
					event.stopImmediatePropagation()
					// Only unlock once.
					if (locked) {
						locked = false
						await config.audioContext.resume()
						await activate()
						clean()
						// Execute callback *after* clean. Otherwise, the callback might trigger
						// events that will be deferred indefinitely.
						if (typeof onActive === 'function') {
							onActive()
							eventSystem.dispatch('playerActive')
						}
					}
				}
				// Trigger deferred events and remove unlock event handler.
				const clean = () => {
					document.removeEventListener('click', unlock, true)
					// It seems we are inexplicably required to trigger the click
					// asynchronously via setTimeout, or the deferred click never triggers.
					setTimeout(() => {
						while (deferred.length > 0) {
							let target = deferred.pop()
							logger.info('Executing deferred click for', target)
							target.click()
						}
					}, 0)
				}
				// Add unlock/defer event handler.
				document.addEventListener('click', unlock, true)
			}
		},
	}
}

syrinscape.player ??= createPlayer()

export const player = syrinscape.player
export default player
