const concertHallConvolverUrl = 'https://dl.dropboxusercontent.com/s/jbcawsshxqf0myr/ConradPrebysConcertHallSeatF111.wav?dl=0'
const stairwellConvolverUrl = 'https://dl.dropboxusercontent.com/s/sjzu2078lg4bpwh/3000CStreetGarageStairwell.wav?dl=0'
const caveConvolverUrl = 'https://dl.dropboxusercontent.com/s/o9ftqlv137mwh0x/Batcave.wav?dl=0'
const defaultConvolverUrl = stairwellConvolverUrl
export {
	defaultConvolverUrl,
	concertHallConvolverUrl,
	stairwellConvolverUrl,
	caveConvolverUrl
}
export const convolverUrls = {
	'Test/Cave': caveConvolverUrl,
	'Test/Stairwell': stairwellConvolverUrl,
	'Test/ConcertHall': concertHallConvolverUrl,
	'Brutalism/BiomedicalSciences': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/BiomedicalSciences.wav',
	'Brutalism/CarpenterCenter': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/CarpenterCenter.wav',
	'Brutalism/GalbraithHall': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/GalbraithHall.wav',
	'Brutalism/GeiselLibrary': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/GeiselLibrary.wav',
	'Brutalism/GraffitiHallway': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/GraffitiHallway.wav',
	'Brutalism/HumanitiesSocialSciencesCourtyard': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/HumanitiesSocialSciencesCourtyard.wav',
	'Brutalism/LoveLibrary': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/LoveLibrary.wav',
	'Brutalism/NaturalSciences': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/NaturalSciences.wav',
	'Brutalism/PacificHall': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/PacificHall.wav',
	'Brutalism/PepperCanyonHall': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/PepperCanyonHall.wav',
	'Brutalism/SanDiegoSupercomputerCenter': 'https://mrchantey.github.io/file-hosting/impulse-responses/Brutalism/SanDiegoSupercomputerCenter.wav',
	'Miscellaneous/AcademicQuadrangle': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/AcademicQuadrangle.wav',
	'Miscellaneous/Amaranth': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/Amaranth.wav',
	'Miscellaneous/BarMonsieurRicard': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/BarMonsieurRicard.wav',
	'Miscellaneous/CastilloDeLosTresReyesDelMorro': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/CastilloDeLosTresReyesDelMorro.wav',
	'Miscellaneous/CastilloDeLosTresReyesDelMorroArch': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/CastilloDeLosTresReyesDelMorroArch.wav',
	'Miscellaneous/CastilloDeLosTresReyesDelMorroCourtyard': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/CastilloDeLosTresReyesDelMorroCourtyard.wav',
	'Miscellaneous/CastilloDeLosTresReyesDelMorroSalasDeExposicion': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/CastilloDeLosTresReyesDelMorroSalasDeExposicion.wav',
	'Miscellaneous/CedarCreekWinery': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/CedarCreekWinery.wav',
	'Miscellaneous/ConvocationMall': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/ConvocationMall.wav',
	'Miscellaneous/CPMC264': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/CPMC264.wav',
	'Miscellaneous/FortalezaDeSanCarlosDeLaCabana ': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/FortalezaDeSanCarlosDeLaCabana .wav',
	'Miscellaneous/FourPointsRoom270': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/FourPointsRoom270.wav',
	'Miscellaneous/HartwellTavern': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/HartwellTavern.wav',
	'Miscellaneous/Hawxhurst': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/Hawxhurst.wav',
	'Miscellaneous/HepnerHall': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/HepnerHall.wav',
	'Miscellaneous/LittlefieldLobby': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/LittlefieldLobby.wav',
	'Miscellaneous/MillsArtMuseum': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/MillsArtMuseum.wav',
	'Miscellaneous/PabellonCulturalDeLaRepublica': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/PabellonCulturalDeLaRepublica.wav',
	'Miscellaneous/PabstBrewery': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/PabstBrewery.wav',
	'Miscellaneous/QuadracciPavilion': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/QuadracciPavilion.wav',
	'Miscellaneous/RedBridge': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/RedBridge.wav',
	'Miscellaneous/SteinmanFoundationRecordingSuite': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/SteinmanFoundationRecordingSuite.wav',
	'Miscellaneous/StorageTankNo7': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/StorageTankNo7.wav',
	'Miscellaneous/TijuanaMall': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/TijuanaMall.wav',
	'Miscellaneous/WangenheimRareBooksRoom': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/WangenheimRareBooksRoom.wav',
	'Miscellaneous/WarrenLectureHall2005': 'https://mrchantey.github.io/file-hosting/impulse-responses/Miscellaneous/WarrenLectureHall2005.wav',
	'Nature/ByronGlacier': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/ByronGlacier.wav',
	'Nature/CliffOfTheDawn': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/CliffOfTheDawn.wav',
	'Nature/DevilsPunchbowl': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/DevilsPunchbowl.wav',
	'Nature/DivorceBeach': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/DivorceBeach.wav',
	'Nature/FatMansMisery': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/FatMansMisery.wav',
	'Nature/FatMansSqueeze': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/FatMansSqueeze.wav',
	'Nature/IslaMujeresCave': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/IslaMujeresCave.wav',
	'Nature/PurgatoryChasm': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/PurgatoryChasm.wav',
	'Nature/StanleyParkCliffs': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/StanleyParkCliffs.wav',
	'Nature/TheSlot': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/TheSlot.wav',
	'Nature/WoodruffLane': 'https://mrchantey.github.io/file-hosting/impulse-responses/Nature/WoodruffLane.wav',
	'Recreation/Art Museum': 'https://mrchantey.github.io/file-hosting/impulse-responses/Recreation/Cranbrook Art Museum.wav',
	'Recreation/HaleHolisticYogaStudio': 'https://mrchantey.github.io/file-hosting/impulse-responses/Recreation/HaleHolisticYogaStudio.wav',
	'Recreation/Natatorium': 'https://mrchantey.github.io/file-hosting/impulse-responses/Recreation/Natatorium.wav',
	'Recreation/OutbackClimbingCenter': 'https://mrchantey.github.io/file-hosting/impulse-responses/Recreation/OutbackClimbingCenter.wav',
	'Recreation/RacquetballCourt': 'https://mrchantey.github.io/file-hosting/impulse-responses/Recreation/RacquetballCourt.wav',
	'Recreation/SewardWaterfrontPark': 'https://mrchantey.github.io/file-hosting/impulse-responses/Recreation/SewardWaterfrontPark.wav',
	'Recreation/Giant Tower': 'https://mrchantey.github.io/file-hosting/impulse-responses/Recreation/Sleeping Giant Tower.wav',
	'Stairwells/3000CStreetGarageStairwell': 'https://mrchantey.github.io/file-hosting/impulse-responses/Stairwells/3000CStreetGarageStairwell.wav',
	'Stairwells/CCRMAStairwell': 'https://mrchantey.github.io/file-hosting/impulse-responses/Stairwells/CCRMAStairwell.wav',
	'Stairwells/ConventionCenterSteps': 'https://mrchantey.github.io/file-hosting/impulse-responses/Stairwells/ConventionCenterSteps.wav',
	'Stairwells/CPMCNorthStairwell': 'https://mrchantey.github.io/file-hosting/impulse-responses/Stairwells/CPMCNorthStairwell.wav',
	'Stairwells/ExerciseAndNutritionSciences': 'https://mrchantey.github.io/file-hosting/impulse-responses/Stairwells/ExerciseAndNutritionSciences.wav',
	'Stairwells/StrathconaStairwellMcGill': 'https://mrchantey.github.io/file-hosting/impulse-responses/Stairwells/StrathconaStairwellMcGill.wav',
	'Stairwells/TransitCenter': 'https://mrchantey.github.io/file-hosting/impulse-responses/Stairwells/TransitCenter.wav',
	'Underground/Batcave': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/Batcave.wav',
	'Underground/BatteryBenson': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/BatteryBenson.wav',
	'Underground/BatteryBrannan': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/BatteryBrannan.wav',
	'Underground/BatteryPowell': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/BatteryPowell.wav',
	'Underground/BatteryQuarles': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/BatteryQuarles.wav',
	'Underground/BatteryRandol': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/BatteryRandol.wav',
	'Underground/BatteryTolles': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/BatteryTolles.wav',
	'Underground/CathedralRoom': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/CathedralRoom.wav',
	'Underground/DiscoveryRoom': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/DiscoveryRoom.wav',
	'Underground/DrainageTunnel': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/DrainageTunnel.wav',
	'Underground/FortWordenPillbox': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/FortWordenPillbox.wav',
	'Underground/FortWordenTunnel': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/FortWordenTunnel.wav',
	'Underground/HarborEntranceControlPost': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/HarborEntranceControlPost.wav',
	'Underground/LakeMerrittBART': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/LakeMerrittBART.wav',
	'Underground/LawrenceWelkCave': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/LawrenceWelkCave.wav',
	'Underground/NancyLakeTunnel': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/NancyLakeTunnel.wav',
	'Underground/PortageCreekTunnel': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/PortageCreekTunnel.wav',
	'Underground/PortTownsendSkatepark': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/PortTownsendSkatepark.wav',
	'Underground/Qasgiq': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/Qasgiq.wav',
	'Underground/SaltonSeaDrainagePipe': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/SaltonSeaDrainagePipe.wav',
	'Underground/SquareVictoriaDome': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/SquareVictoriaDome.wav',
	'Underground/TijuanaAqueductTunnel': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/TijuanaAqueductTunnel.wav',
	'Underground/TonyKnowlesCoastalTrailTunnel': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/TonyKnowlesCoastalTrailTunnel.wav',
	'Underground/TunnelToHeaven': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/TunnelToHeaven.wav',
	'Underground/TunnelToHell': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underground/TunnelToHell.wav',
	'Underpasses/5UnderpassValencia': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/5UnderpassValencia.wav',
	'Underpasses/ArroyoEnsenada': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/ArroyoEnsenada.wav',
	'Underpasses/Avenue52UnderpassLARiver': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/Avenue52UnderpassLARiver.wav',
	'Underpasses/Avenue60UnderpassLARiver': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/Avenue60UnderpassLARiver.wav',
	'Underpasses/BoulevardRosemontUnderpass': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/BoulevardRosemontUnderpass.wav',
	'Underpasses/CaribooRdUnderGaglardiWay': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/CaribooRdUnderGaglardiWay.wav',
	'Underpasses/CleftRidgeArch': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/CleftRidgeArch.wav',
	'Underpasses/Commerical&5Underpass': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/Commerical&5Underpass.wav',
	'Underpasses/DipwayArch': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/DipwayArch.wav',
	'Underpasses/EchoBridge': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/EchoBridge.wav',
	'Underpasses/FishCreekTrestleBridge': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/FishCreekTrestleBridge.wav',
	'Underpasses/FremontTroll': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/FremontTroll.wav',
	'Underpasses/HopkinsDriveUnderpass': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/HopkinsDriveUnderpass.wav',
	'Underpasses/JFKUnderpass': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/JFKUnderpass.wav',
	'Underpasses/LionsGateBridge': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/LionsGateBridge.wav',
	'Underpasses/OldSouthBridge': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/OldSouthBridge.wav',
	'Underpasses/RiverMountainsLoopTrailAqueduct': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/RiverMountainsLoopTrailAqueduct.wav',
	'Underpasses/StanleyParkCauseway': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/StanleyParkCauseway.wav',
	'Underpasses/StanleyParkDriveUnderpass': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/StanleyParkDriveUnderpass.wav',
	'Underpasses/SwitzerStUnderEHarborDr': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/SwitzerStUnderEHarborDr.wav',
	'Underpasses/TelephoneWash': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/TelephoneWash.wav',
	'Underpasses/WalkwayUnderECampusDr': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/WalkwayUnderECampusDr.wav',
	'Underpasses/WaterplacePark': 'https://mrchantey.github.io/file-hosting/impulse-responses/Underpasses/WaterplacePark.wav',
	'Venues/ConradPrebysConcertHallSeatF111': 'https://mrchantey.github.io/file-hosting/impulse-responses/Venues/ConradPrebysConcertHallSeatF111.wav',
	'Venues/MillsGreekTheater': 'https://mrchantey.github.io/file-hosting/impulse-responses/Venues/MillsGreekTheater.wav',
	'Venues/NaumburgBandshell': 'https://mrchantey.github.io/file-hosting/impulse-responses/Venues/NaumburgBandshell.wav',
	'Venues/Space4ArtGallery': 'https://mrchantey.github.io/file-hosting/impulse-responses/Venues/Space4ArtGallery.wav',
	'Venues/SteinmanHall': 'https://mrchantey.github.io/file-hosting/impulse-responses/Venues/SteinmanHall.wav',
}