import { checkStatus, throwNoAuthError } from './errors'
import { createUrlSystem, iUrlSystem } from 'modules-core/urlSystem'
import config from 'modules-core/config'

interface iArgs {
	urlSystem?: iUrlSystem
}



export function createAuthSystem({
	urlSystem,
}: iArgs = {}) {
	urlSystem = urlSystem || createUrlSystem()

	const authSystem = {
		authorizedFetchUrl: async (url: string, opts = {} as Auth.iRequestOpts, checkResponseStatus = true) => {
			opts.headers = { ...opts.headers, 'Authorization': `Token ${config.token}` }
			const response = await fetch(url, opts)
			checkResponseStatus && checkStatus(response)
			return response
		},
		authorizedFetchBlob: async (url: string, opts = {} as Auth.iRequestOpts, checkResponseStatus = true) => {
			const response = await authSystem.authorizedFetchUrl(url, opts, checkResponseStatus)
			return await response.blob()
		},
		authorizedFetchPath: async (urlPath: string, opts = {} as Auth.iRequestOpts) => {
			const url = urlSystem.createUrlHttp(urlPath)
			return authSystem.authorizedFetchUrl(url, opts)
		},

	}
	return authSystem
}


// export const fetchWithQueryParamsToken = async ({ url, opts, authState }: iHandleAuthorizedFetch) => {
//	 const seperator = url.includes('?') ? '&' : '?'
//	 url = `${url}${seperator}${objectToQueryParams({ auth_token: authState.authToken })}`
//	 return await fetch(url, opts)
// }

export type iAuthSystem = ReturnType<typeof createAuthSystem>
