

export const throwDumbMode = () => {
	throw new Error('cannot create element directly from params - running player in dumb mode')
}


(window as any).__dumbStubs = {
	elementParamsToPlaylistData: throwDumbMode,
	elementParamsToSpatialData: throwDumbMode
}