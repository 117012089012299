import { iUrlSystem } from 'modules-core/urlSystem'
import { createEvent, createSimpleRecord, iEvent, MathExt, runWithTimeout } from 'modules-core/utility'
import { Client, Server, SocketState } from './types'
import { MessageHandlerMap, ReconnectParams } from './types/Server'
import log from 'modules-core/log'
import { oneLine } from 'common-tags'
import { eventSystem } from 'modules-core/eventSystem'
import config from 'modules-core/config'

const logger = log.getLogger('socketSystem')

interface Args {
	urlSystem: iUrlSystem
  logLevel?: 'quiet'|'verbose'
}

export const SocketSystem = ({urlSystem,logLevel}:Args)=>{
	const BACKOFF_INITIAL = 500  // Half a second
	const BACKOFF_MAX = 60 * 1000  // 1 minute
	const BACKOFF_MULTIPLIER = 2  // Seconds

	let backoff = 0
	let last_connect: number
	let state: SocketState = 'Idle'
	let ws: WebSocket

	const onMessageRecord = createSimpleRecord<Server.MessageName, iEvent<Server.MessageParams>>(createEvent)

	const system = {
		get state() { return state },
		set state(_state: SocketState){
			state = _state
			system.onStateChange.invoke(state)
		},

		connect: async () =>
			new Promise<void>((resolve, reject) => {
				system.state = 'Connecting'
				const url = urlSystem.createUrlWs('ws-web-player/websocket')
				ws = new WebSocket(url)
				ws.onclose = () => {
					system.state = 'Idle'
					// Reconnect with progressive backoff. Count the backoff from the last
					// connect, not from the last disconnect.
					backoff = Math.min(
						Math.max(backoff * BACKOFF_MULTIPLIER, BACKOFF_INITIAL),
						BACKOFF_MAX,
					)
					// Subtract current connection duration from backoff.
					backoff -= Math.min(Date.now() - last_connect, backoff) || 0
					logger.info(
						`Connection closed. Reconnect in ${MathExt.millisToSecs(backoff)} seconds.`
					)
					setTimeout(system.connect, backoff)
					// Unset last connect time, because we are disconnected and have already set
					// the backoff timeout. If we successfully connect, we will set it again.
					last_connect = undefined
				}
				ws.onerror = (event) => {
					reject()
					if (system.state !== 'Error') {
						system.state = 'Error'
					}
					system.onError.invoke(event)
					ws.close()
				}
				ws.onmessage= (event) => {
					if (system.state !== 'Active') {
						system.state = 'Active'
					}
					const data = JSON.parse(event.data) as Server.Message
					logger.debug('<<< Socket Message Received:', data)
					system.onMessage.invoke(data)
					system.onNamedMessage(data.message).invoke(data.params)
				}
				ws.onopen = (event) => {
					resolve()
					last_connect = Date.now()
					system.send({
						message:'register',
						session_id: config.sessionId,
						token: config.token,
					})
				}
			}),

		onMessage:createEvent<Server.Message>(),
		onNamedMessage:onMessageRecord.getOrCreate as
            <T extends Server.MessageName>(key: T) => iEvent<Server.MessageParamsMap[T]>,
		reconnect: () => {
			if (ws !== undefined) {
				logger.info("reconnect()")
				backoff = 0
				ws.close()
			} else {
				logger.info("reconnect() called before connect()")
			}
		},
		send:<T extends Client.MessageName>(data:Client.Message<T>)=>{
			logger.debug('>>> Socket Message Sent:', data)
			ws.send(JSON.stringify(data))
		},
		onError:createEvent<Event>(),
		onStateChange:createEvent<SocketState>(),
	}

	const socketMessageLookup: MessageHandlerMap = {
		ping: (params) => system.send({ message: 'ping', params }),

		error: (params) => logger.error(params.message),

		reconnect_to_new_backend: ({ subdomain, domain_and_port }: ReconnectParams) => {
			const [domain, port] = domain_and_port.split(':')
			config.wsHostname = `${subdomain}.${domain}`
			if (port) config.wsPort = port
			logger.info(oneLine`
				Reconnect to new backend: ${urlSystem.createUrlWs('ws-web-player/websocket')}
			`)
			system.reconnect()
		},
	}

	Object.entries(socketMessageLookup)
		.forEach(([key,func]:[Server.MessageName,Server.MessageHandler])=>
			system.onNamedMessage(key).addListener(func))

	eventSystem.add('updateConfig', (event) => {
		if (event.detail.sessionId || event.detail.token) {
			system.reconnect()
		}
	})

	return system
}
export type SocketSystem = ReturnType<typeof SocketSystem>
