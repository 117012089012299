import { iDatabase } from './types'
import { cleanKey, unCleanKey } from './utility';

export const createCacheStorageDatabase = (name = 'database'): iDatabase => {
	// `createCacheStorageDatabase()` is not async, so we cannot `await caches.open(...)`.
	// Instead, we keep a reference to the promise and `await openCache` inside each
	// database function (below).
	let openCache: Promise<Cache> = caches.open(name)

	const db: iDatabase = {
		getItem: async (key) => {
			const cache = await openCache
			const response = await cache.match(new Request(cleanKey(key)))
			if (!response) {
				return null
			}
			return response.blob()
		},
		setItem: async (key, val) => {
			const cache = await openCache
			await cache.put(
				new Request(cleanKey(key)),
				new Response(val, { headers: { 'Content-Length': val.size.toString() } })
			)
		},
		removeItem: async (key) => {
			const cache = await openCache
			await cache.delete(new Request(cleanKey(key)))
		},
		clear: async () => {
			const cache = await openCache
			await caches.delete(name)
			openCache = caches.open(name)
		},
		getKeys: async () => {
			const cache = await openCache
			return (await cache.keys()).map((request) => unCleanKey(request.url))
		},
	}
	return db
}
