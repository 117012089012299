import { createAudioEffect } from '.'
import { createDefaultEffectParams } from '../constants'
import { AudioEffect } from '../types'
import config from 'modules-core/config'

export const createCompressorEffect = (params: AudioEffect.CompressorEffectParams = {}) => {
	params = Object.assign(createDefaultEffectParams('compressor'), params)
	if (params.isDestination)
		params.output = undefined

	// TODO: Use destructuring assignment for `params`?
	const node = new DynamicsCompressorNode(config.audioContext, params)

	const effect: AudioEffect.CompressorEffect = {
		params,
		setParam: {},
		...createAudioEffect(node),
	}
	return effect
}
