import { Element } from './types'
import { eventSystem } from 'modules-core/eventSystem'
import { filterByElementId } from './utility'

// Register events.
eventSystem.add('setElementVolume')

interface Args {
	elementMap: Element.MutableMap
}
export const createElementVolumeSystem = ({ elementMap }: Args) => {

	const elementVolumeMap = new Map<number, number>()

	const system = {
		elementVolumeMap,
		setElementIdVolume: (elementId: number, value: number) => {
			if (elementVolumeMap.get(elementId) === value)
				return
			elementVolumeMap.set(elementId, value)
			filterByElementId(elementMap, elementId)
				.forEach(([, element]) => element.setVolume(value))
			eventSystem.dispatch('setElementVolume', {
				elementId,
				volume: value,
			})
		}
	}
	return system
}

export type ElementVolumeSystem = ReturnType<typeof createElementVolumeSystem>
