



interface iArgs {
	prefix?: string
}
export const createUuidSystem = ({ prefix }: iArgs = {}) => {
	prefix = prefix || Math.random().toFixed(8)
	let i = 0
	return {
		nextId: () => `${prefix}-${i++}`
	}
}

export type iUuidSystem = ReturnType<typeof createUuidSystem>