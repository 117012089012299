import $ from 'jquery'
import config from 'modules-core/config'
import log from 'modules-core/log'
import player from 'modules-core/player'
import syrinscape from 'app/common/syrinscape'
import visualisation from 'modules-core/visualisation'
import { eventSystem } from 'modules-core/eventSystem'

const logger = log.getLogger('homePlayer')

export const createHomeUI = () => {
	logger.info('createHomeUI()')

	let moodNames = {}

	return {
		init: () => {
			// Sticky player detection.
			// See: https://stackoverflow.com/a/57991537/2829685
			let stickyElement = document.querySelector('#home-player')
			let observer = new IntersectionObserver(
				([e]) => {
					stickyElement.classList.toggle('sticky', e.intersectionRatio < 1)
				}, {
					rootMargin: '-1px 0px 0px 0px',
					threshold: [1],
				},
			)
			observer.observe(stickyElement)

			let initialConfig = {
				sessionId: '',
				token: '',
			}

			function onActive() {
				player.elementSystem.init({ analyse: false })

				// Visualisations.
				visualisation.add('global', () => {
					let data = player.audioEffectSystem.analyser.getData()
					visualisation.d3VisualiseFrequencyData(data, '.d3-frequency')
					visualisation.d3VisualiseWaveformData(data, '.d3-waveform')
					return player.audioEffectSystem.analyser.isActive
				})
				visualisation.init()

				// Get local volume and mute elements.
				const volume = $('#volume')
				const mute = $('#mute')

				// Local volume control events.
				$(document)
					.on('input', '#volume', event => {
						player.audioSystem.setLocalVolume(volume.val() as number / 100)
						logger.debug(`Set volume: ${volume.val()}`)
					})
					.on('change', '#volume', event => {
						// Update mute control.
						if (volume.val() === '0') {
							mute.attr('data-hint', 'Unmute')
						} else {
							mute.attr('data-hint', 'Mute')
							// Not muted. Save current local volume, so we can restore on unmute.
							config.lastLocalVolume = volume.val() as number / 100
						}
						logger.info(`Set volume: ${volume.val()}`)
					})

				// Mute control events.
				$(document).on('click', '#mute', event => {
					if (volume.val() === '0') {
						// Restore last local volume to unmute.
						player.audioSystem.setLocalVolume(config.lastLocalVolume)
					} else {
						// Save current local volume and mute.
						config.lastLocalVolume = volume.val() as number / 100
						player.audioSystem.setLocalVolume(0)
					}
					volume.trigger('change') // Update mute control
				})

				// Update local volume and mute controls when volume is set via event.
				eventSystem.add('setLocalVolume', (event: CustomEvent) => {
					volume
						.attr('data-hint', `${Math.round(event.detail * 100)}%`)
						.val(event.detail * 100)
				})

				// Set initial local volume and mute control state from config.
				player.audioSystem.setLocalVolume(config.localVolume)
				volume.trigger('change') // Update mute control

				let status = $('.status-container')
				let stopAll = () => {
					// Stop all.
					player.controlSystem.stopAll()
					$('.toggleSoundset-active').removeClass('toggleSoundset-active')
					// Toggle status icon.
					status.html(`<i class="fa-play-circle fa-3x far"></i>`)
					// Toggle `active` styles.
					$('#home-player').removeClass('active')
				}

				// Toggle soundset.
				$(document).on('click', '.toggleSoundset', (event) => {
					event.preventDefault()
					let target = $(event.target).closest('.toggleSoundset')
					// Save toggled soundset data to player status control, so we can stop/start
					// the current soundset from there.
					const id = target.attr('data-id')
					status.attr('data-artwork', target.attr('data-artwork'))
					status.attr('data-id', id)
					status.attr('data-name', target.attr('data-name'))
					// Start.
					if (!target.hasClass('toggleSoundset-active')) {
						// Toggle start/stop button.
						$('.toggleSoundset-active').removeClass('toggleSoundset-active')
						$(`.toggleSoundset[data-id=${id}]`).addClass('toggleSoundset-active')
						// Start mood.
						player.controlSystem.startMood(id)
						// Update artwork and now playing.
						$('.artwork-background').attr('src', target.attr('data-artwork'))
						$('.now-playing').text(target.attr('data-name'))
						// Toggle status icon.
						status.html(`<i class="fa-stop-circle fa-3x far"></i>`)
						// Toggle `active` styles.
						$('#home-player').addClass('active')
					}
					// Stop.
					else {
						stopAll()
					}
				})

				// Start mood.
				$(document).on('click', '.startMood', (event) => {
					event.preventDefault()
					const id = $(event.target).closest('.startMood').attr('data-id')
					let toggle = $(`.toggleSoundset[data-id=${id}]`)
					if (toggle.length) {
						toggle.first().trigger('click')
					} else {
						player.controlSystem.startMood(id)
						// Toggle `active` styles.
						$('#home-player').addClass('active')
					}
				})

				// Stop all.
				$(document).on('click', '.stopAll', stopAll)
			}

			// Replace spinner with play icon.
			setTimeout(() => {
				$('.status-container').fadeOut(100, () => {
					$('.status-container').html('<i class="fa-play-circle fa-3x far"></i>').fadeIn(100)
				})
			}, 400)

			let selector = '.startElement, .startMood, .stopAll, .toggleSoundset'
			player.init({ context: 'home', initialConfig, onActive, selector })
		},
		moodNames,
	}
}

syrinscape.homeUI ??= createHomeUI()

export const homeUI = syrinscape.homeUI
export default homeUI
