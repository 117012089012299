
export const throwNoAuthError = () => { throw new NotYetAuthorizedError() }


export class AuthenticationError extends Error {
	constructor(message = '') {
		super(message)
		this.name = 'AuthenticationError'
	}
}

export class MissingParameterError extends Error {
	constructor(message = '') {
		super(message)
		this.name = 'MissingParameterError'
	}
}

export class NotYetAuthorizedError extends Error {
	constructor(message = '') {
		super(message)
		this.message = 'Auth token has not been set, please authorize before making requests'
		this.name = 'NotYetAuthorizedError'
	}
}

export class StatusError extends Error {
	constructor(status: number) {
		super()
		this.name = 'StatusError'
		this.message = `request returned a ${status} status`
	}
}
export class UnexpectedDataError extends Error {
	constructor(message = '') {
		super(message)
		this.name = 'UnexpectedDataError'
	}
}


export function checkStatus(response: Response) {
	// if (response.status !== 200)
	if (response.status >= 400)
		throw new StatusError(response.status)
	return response
}