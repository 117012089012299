import { AudioEffect } from 'modules-core/audioEffectSystem/types'
import { Element } from 'modules-core/elementSystem/types'
import { Sample } from 'modules-core/sampleSystem/types'
import { UnitySocket } from 'modules-core/socket/types'


export const deepClone = <T>(val: T) =>
	JSON.parse(JSON.stringify(val)) as T

export const parseFloatOr = (fallback: number, val?: string) =>
	parseFloat(val) || fallback
export const parseFloatOrZero = (val?: string) =>
	parseFloatOr(0, val)
export const parseFloatOrOne = (val?: string) =>
	parseFloatOr(1, val)

export const parseFloatSecondsToMillis = (str?: string) =>
	parseFloatOrZero(str) * 1000

export const parseElementType = (type?: UnitySocket.ElementType) => type || 'sfx'

const parseBoolOr = (val: boolean | undefined, fallback: boolean) =>
	val === true ? true : val === false ? false : fallback

export const parseRepeat = (params: UnitySocket.Element) =>
	params.type === 'oneshot'
		? false
		: params.playlist_order === 'random'
			? true
			: params.repeat_playlist === false
				? false
				: true

export const parseLoop = (sampleDelayMin: number, sampleDelayMax: number) =>
	sampleDelayMin < 0
	&& sampleDelayMax < 0
	&& Math.abs(sampleDelayMax - sampleDelayMin) < 0.001

export const socketElementToParams = (params: UnitySocket.Element): Element.Params => ({
	title: params.name,
	playlistParams: socketElementToPlaylistParams(params),
	type: parseElementType(params.type),
	presetName: params.reverb_preset as AudioEffect.Preset,
	elementId: cleanElementId(params.id),
	spatialParams: socketElementToSpatialParams(params),
})

export const socketElementToPlaylistParams = (params: UnitySocket.Element): Element.PlaylistParams => {
	const sampleDelayMin = parseFloatSecondsToMillis(params.min_wait)
	const sampleDelayMax = parseFloatSecondsToMillis(params.max_wait)
	const order = params.playlist_order as Element.PlaylistOrder
	const loop = parseLoop(sampleDelayMin, sampleDelayMax)
	const repeat = loop || parseRepeat(params)
	return {
		order,
		loop,
		repeat,
		sampleDelayMin,
		sampleDelayMax,
		sampleDelayPos: params.allow_samples_to_overlap ? 'start' : 'end',
		elementDelayMin: parseFloatSecondsToMillis(params.min_start_delay),
		elementDelayMax: parseFloatSecondsToMillis(params.max_start_delay),
		sampleParamsList: params.playlist ? params.playlist.map(socketSampleToSampleParams) : []
	}
}

export const socketElementToSpatialParams = (params: UnitySocket.Element): Element.SpatialParams | undefined => {
	params.position_samples ??= true
	if (!params.position_samples)
		return
	return {
		minDist: parseFloatOrZero(params.min_distance),
		maxDist: parseFloatOrZero(params.max_distance),
		minAngle: parseFloatOrZero(params.min_angle),
		maxAngle: parseFloatOrZero(params.max_angle),
		speed: parseFloatOrZero(params.speed)
	}
}

export const socketSampleToSampleParams = (params: UnitySocket.Sample): Sample.Params => ({
	spatializable: parseBoolOr(params.is_3d, true),
	name: params.name,
	duration: parseFloatSecondsToMillis(params.len),
	url: params.url,
	sampleId: cleanSampleId(params.s_id || params.id),
	minGain: parseFloatOrOne(params.min_gain),
	maxGain: parseFloatOrOne(params.max_gain)
})


export const socketMoodElementToParams = (moodElement: UnitySocket.Mood['moodelements'][number]): Element.Params => {
	const params = socketElementToParams(moodElement.element)
	params.playlistParams.useElementDelay = true
	return params
}

export const cleanElementId = (id:string)=>
	id && Number(id.replace('e_',''))
export const cleanSampleId = (id:string)=>
	id && Number(id.replace('s_',''))