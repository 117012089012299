import { createAudioEffect } from './createEffect'
import { AudioEffect } from '../types'
import { effectMetadataMap, createDefaultEffectParams } from '../constants'
import config from 'modules-core/config'

export const createDelayEffect = (params: AudioEffect.DelayEffectParams) => {
	params = Object.assign(createDefaultEffectParams('delay'), params)
	const node = config.audioContext.createDelay(
		//@ts-ignore
		effectMetadataMap.delay.parameters.duration.max
	)
	node.delayTime.value = params.duration

	const feedbackNode = config.audioContext.createGain()
	feedbackNode.gain.value = params.falloff

	node.connect(feedbackNode)
	feedbackNode.connect(node)

	const effect = {
		//we use feedback node so falloff is applied on first pass
		params,
		...createAudioEffect(feedbackNode, node),
		setParam: {
			'duration': val => node.delayTime.value = val,
			'falloff': val => feedbackNode.gain.value = val,
		}
	} as AudioEffect.DelayEffect
	return effect
}
