import { createAudioEffect } from './createEffect'
import { AudioEffect } from '../types'
import { createDefaultEffectParams } from '../constants'
import { SkinnerAdvancedReverb } from './SkinnerAudio'
import config from 'modules-core/config'

export const createReverbEffect = (params: AudioEffect.ReverbEffectParams) => {
	params = Object.assign(createDefaultEffectParams('reverb'), params)

	const reverb = new SkinnerAdvancedReverb(config.audioContext)
	reverb.setup({ ...params })
	reverb.renderTail({ ...params })

	const effect = {
		params,
		...createAudioEffect(reverb.input, reverb.output),
		setParam: {
		}
	} as AudioEffect.ReverbEffect
	return effect
}
