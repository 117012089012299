import { createCompositeDatabase } from './compositeDatabase'
import { createCustomDatabase } from './customDatabase'
import { iFetch } from './types'
import log from 'modules-core/log'
import syrinscape from 'app/common/syrinscape'
import { createStorageDatabase } from './storageDatabase'
import { formatBytes } from 'modules-core/utility/MathExt'
import { oneLine, source } from 'common-tags'

const logger = log.getLogger('blobDatabase')

interface iArgs {
	fetchMethod?: iFetch
}

const createBlobDatabase = ({
	fetchMethod,
}: iArgs = {}) => {
	logger.info("createBlobDatabase()")

	fetchMethod ??= window.fetch
	// const memoryDB = createObjectDatabase()

	if (navigator.storage) {
		if (navigator.storage.persist) {
			navigator.storage.persist().then(function(persistent) {
				if (persistent) {
					logger.info(
						'Storage is persistent. Will not be cleared except by explicit user action.'
					)
				} else {
					logger.info(
						'Storage is temporary. May be cleared by the UA under storage pressure.'
					)
				}
			})
		}
		const estimateStorage = async () => {
			if (navigator.storage.estimate) {
				const estimate = await navigator.storage.estimate()
				logger.info(`Storage limit: ${formatBytes(estimate.quota)}`)
				logger.info(oneLine`
					Storage used: ${formatBytes(estimate.usage)}
					(${(estimate.usage / estimate.quota * 100).toFixed(2)}%)
				`)
			}
		}
		estimateStorage()
	}

	const localDatabase = createStorageDatabase('blob-cache')

	const remoteDatabase = createCustomDatabase({
		getItem: async (key: string) => {
			blobDatabase._onFetch(key)
			const response = await fetchMethod(key)
			if (response.status !== 200)
				throw new Error(`bad response: ${response}`)
			const blob = await response.blob()
			return blob
		}
	}, true)

	const compositeDatabase = createCompositeDatabase([
		// memoryDB,
		localDatabase,
		remoteDatabase
	])

	const blobDatabase = {
		getBlob: (key: string): Promise<Blob> => compositeDatabase.getItem(key),
		// clear: compositeDatabase.clear,
		// getBlobURL: blobURLDatabase.getBlobURL,
		// removeBlobURL: blobURLDatabase.removeBlobURL,
		_onFetch: (key: string) => { },
		// _memoryDB: memoryDB,
		_localDatabase: localDatabase,
		_remoteDatabase: remoteDatabase,
		// _blobURLDatabase: blobURLDatabase,
		_compositeDatabase: compositeDatabase
	}

	return blobDatabase
}

export type iBlobDatabase = ReturnType<typeof createBlobDatabase>

syrinscape._r.blobDatabase ??= createBlobDatabase()

export const blobDatabase = syrinscape._r.blobDatabase
export default blobDatabase
