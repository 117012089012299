import { createDefaultEffectParams } from '..'
import { AudioEffect } from '../types'
import { createAudioEffect } from './createEffect'
import config from 'modules-core/config'


export const createSendEffect = (params: AudioEffect.SendEffectParams = {}) => {
	params = Object.assign(createDefaultEffectParams('send'), params)

	const input = config.audioContext.createGain()
	const wet = config.audioContext.createGain()
	const dry = config.audioContext.createGain()
	// const destination = send.getOutputs()[0]

	// const effectBase =

	const effect: AudioEffect.SendEffect = {
		params,
		...createAudioEffect(input, wet),
		connectFromMap: map =>
			dry.connect(map[params.dryOutput].input),
		setWet: (value: number) => {
			wet.gain.value = value
			dry.gain.value = 1 - value
		},
		getWet: () => wet.gain.value,
		setParam: {
			'wetness': (val) => effect.setWet(val)
		},
		_input: input,
		_wet: wet,
		_dry: dry
	}

	input.connect(wet)
	input.connect(dry)
	effect.setWet(params.wetness)

	return effect
}
