import { iDatabase } from './types'






//the 'get' database is designed to be the last in a comosite database
export const createCustomDatabase = (db: Partial<iDatabase>, suppressWarning = true) => {

	const warn = suppressWarning
		? async () => { }
		: async () => console.warn('\'get\' database is immutable')

	const dbDefault: iDatabase = {
		getItem: warn,
		setItem: warn,
		clear: warn,
		removeItem: warn,
		getKeys: () => {
			warn()
			const keys: string[] = []
			return new Promise(resolve => resolve(keys))
		},
	}

	return { ...dbDefault, ...db }
}
