import { createAsyncAudioSource, FadeOptions } from 'modules-core/audioSystem/types'
import { blobDatabase } from 'modules-core/cache/blobDatabase'
import { createVolumeEffect } from 'modules-core/audioEffectSystem/effects/volumeEffect'
import { AudioEffect } from 'modules-core/audioEffectSystem/types'
import { AudioEffectSystem } from 'modules-core/audioEffectSystem'
import { createEvent, wait } from 'modules-core/utility'
import log from 'modules-core/log'
import { source } from 'common-tags'
import { clearStrictTimeout, setStrictTimeout } from 'modules-core/utility/strictTimeout'
import config from 'modules-core/config'

const logger = log.getLogger('audioSystem')

interface Args {
	effectSystem: AudioEffectSystem
}
export const createBlobSourceInstantiator = ({
	effectSystem
}: Args) => {

	const audioElements: HTMLAudioElement[] = []
	const sourceNodes = new WeakMap()  // {el: HTMLAudioElement: sourceNode: MediaElementAudioSourceNode}

	const instantiator = {
		createAudioSource: (src, output: AudioEffect.HasInput = effectSystem.globalVolume, { fadeIn, fadeOut, fadeScrub, fadeStop }: FadeOptions = {}, targetVolume?: number) => {
			// fadeIn ??= 0.1
			// curveName ??= 'easeIn'
			fadeIn ??= 0.03
			fadeOut ??= 0.03
			fadeScrub ??= 3
			fadeStop ??= 3
			if (!isFinite(targetVolume))
				targetVolume = 1

			let el: HTMLAudioElement
			if (audioElements.length) {
				el = audioElements.shift()
				let i = 0
				while (el.ended && i < audioElements.length) {
					i += 1
					audioElements.push(el)
					el = audioElements.shift()
				}
			} else {
				el = new Audio()
				el.autoplay = false
				el.crossOrigin = 'anonymous'
				sourceNodes.set(el, config.audioContext.createMediaElementSource(el))
			}
			const volume = createVolumeEffect()

			let isAlive = true
			const onPlay = createEvent()
			const onDispose = createEvent()
			let fadeOutHandler: any

			const clearFadeoutHandler = () =>
				fadeOutHandler && clearStrictTimeout(fadeOutHandler)

			const audioSource = createAsyncAudioSource({
				isAlive,
				onPlay,
				onDispose,
				play: async (currentTime = 0) => {
					const blob = await blobDatabase.getBlob(src)
					el.src = URL.createObjectURL(blob)
					el.currentTime = currentTime
					volume.value = 0
					el.addEventListener('ended', handleEnded)
					await el.play()
					onPlay.invoke()
					fadeOutHandler = setStrictTimeout(async () =>
						await volume.fadeToValue(0, fadeOut, 'easeIn')
					, (el.duration - fadeOut) * 1000)
					if (currentTime)
						await volume.fadeToValue(targetVolume, fadeScrub, 'easeIn')
					else
						await volume.fadeToValue(targetVolume, fadeIn, 'easeIn')
				},
				stop: async () => {
					if (!isAlive) {
						// Break the recursive chain between `sampleInstance` and `audioSource`.
						logger.debug(source`
							audioSource.stop(): Already dead! Cannot stop when dead! This is probably a bug.
								el.src: ${el.src}
								src: ${src}
						`)
						return
					}
					logger.debug(source`
						audioSource.stop():
							fadeStop: ${fadeStop}
							el.src: ${el.src}
							src: ${src}
					`)
					clearFadeoutHandler()
					await volume.fadeToValue(0, fadeStop)
					await dispose()
				}
			})

			const dispose = async () => {
				if (!isAlive) {
					logger.debug(source`
						audioSource.dispose(): Already dead! Can only dispose once!
							el.src: ${el.src}
							src: ${src}
					`)
					return
				}
				logger.debug(source`
					audioSource.dispose():
						el.src: ${el.src}
						src: ${src}
				`)
				isAlive = false
				clearFadeoutHandler() // No-op if stop() called already
				await wait(1000)//wait before disconnect, required for delay effects
				volume.disconnectAll()
				sourceNodes.get(el).disconnect()
				el.currentTime = 0
				el.pause()
				URL.revokeObjectURL(el.src)
				el.removeEventListener('ended', handleEnded)
				audioElements.push(el)
				onDispose.invoke()
			}

			const handleEnded = () => {
				if (!isAlive) {
					logger.debug(source`
						audioSource.handleEnded(): Already dead! Can only handle ended once!
							el.src: ${el.src}
							src: ${src}
					`)
					return
				}
				dispose()
			}

			const init = async () => {
				sourceNodes.get(el).connect(volume.input)
				volume.connect(output)

				audioSource.handleReady()
			}
			init()

			return audioSource
		}
	}
	return instantiator
}
