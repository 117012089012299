import { createAudioEffect } from './createEffect'
import { AudioEffect } from '../types'
import { createDefaultEffectParams, effectMetadataMap } from '../constants'
import config from 'modules-core/config'
import Freeverb from 'freeverb'

//https://github.com/mmckegg/freeverb

interface Freeverb extends GainNode {
	roomSize: number
	dampening: number
	wet: AudioParam
	dry: AudioParam
}

export const createFreeverbEffect = (params: AudioEffect.FreeverbEffectParams) => {
	params = Object.assign(createDefaultEffectParams('freeverb'), params)

	const preDelay = config.audioContext.createDelay(
		//@ts-ignore
		effectMetadataMap.freeverb.parameters.preDelay.max
	)
	preDelay.delayTime.value = params.preDelay

	const reverb = Freeverb(config.audioContext) as Freeverb
	reverb.wet.value = 1
	reverb.dry.value = 0
	reverb.roomSize = params.roomSize
	reverb.dampening = params.dampening

	const hp = config.audioContext.createBiquadFilter()
	hp.type = 'highpass'
	hp.frequency.value = params.hpFrequency
	hp.Q.value = params.hpResonance

	const lp = config.audioContext.createBiquadFilter()
	lp.type = 'lowpass'
	lp.frequency.value = params.lpFrequency
	lp.Q.value = params.lpResonance

	const output = config.audioContext.createGain()
	output.gain.value = params.gain

	preDelay.connect(reverb)
	reverb.connect(hp)
	hp.connect(lp)
	lp.connect(output)

	const effect = {
		params,
		...createAudioEffect(preDelay, output),
		setParam: {
			preDelay: val => preDelay.delayTime.value = val,
			dampening: val => reverb.dampening = val,
			roomSize: val => reverb.roomSize = val,
			hpFrequency: val => hp.frequency.value = val,
			hpResonance: val => hp.Q.value = val,
			lpFrequency: val => lp.frequency.value = val,
			lpResonance: val => lp.Q.value = val,
			gain: val => output.gain.value = val,
		}
	} as AudioEffect.FreeverbEffect
	return effect
}
