import { deepClone } from 'modules-core/utility/ParseExt'
import { Element } from './types'
import { Sample } from 'modules-core/sampleSystem/types'

export const filterByElementId = (map: Element.MutableMap, elementId: number) =>
	[...map.getRaw()]
		.filter(([key, element]) => element.elementId === elementId)

export const filterBySampleId = (map: Sample.MutableMap, sampleId: number) =>
	[...map.getRaw()]
		.filter(([key, sample]) => sample.sampleId === sampleId)

export const stripElementInstanceToData = ({
	title, elementId, type, presetName, playlistParams, spatialParams, uuid, playlistData
}: Element.Instance): Element.Data =>
	deepClone({ title, elementId, type, presetName, playlistParams, spatialParams, uuid, playlistData })



export const stripElementDataToParams = ({ uuid, playlistData, ...params }: Element.Data): Element.Params =>
	deepClone(params)
