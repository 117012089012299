import rootLogger from 'loglevel'
import prefixer from 'loglevel-plugin-prefix'
import getServerTime from 'modules-core/utility/serverTime'
import syrinscape from 'app/common/syrinscape'

prefixer.reg(rootLogger)
prefixer.apply(rootLogger, {
	template: '[%t] %l (%n):',
	timestampFormatter: function (date) {
		const serverDate = new Date(getServerTime(date.getTime()))
		return serverDate.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1')
	},
})

const allSetLevel = (level: rootLogger.LogLevelDesc, persist: boolean = true) => {
	Object.values(rootLogger.getLoggers()).forEach((logger) => logger.setLevel(level, persist))
}

const allResetLevel = () => {
	Object.values(rootLogger.getLoggers()).forEach((logger) => logger.resetLevel())
}

syrinscape.log ??= {
	...rootLogger,
	allSetLevel,
	allResetLevel,
}

export const log = syrinscape.log
export default log
