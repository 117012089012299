import log from "modules-core/log"

let checkTimersId: number
let checkTimersInterval = 1000 / 60
let logger = log.getLogger('strictTimeout')
let nextId = 1
let timers = new Map<number, Timer>()

interface Timer {
	start: number
	end: number
	callback: () => any
}

let checkTimers = () => {
	timers.forEach((timer, id) => {
		let { start, end, callback } = timer
		let now = new Date().getTime()
		if (start < now) {
			timers.delete(id)
			// Allow 5s grace to match the 5s grace we allow on scrubbing samples.
			if (typeof end === 'undefined' || end > now - 5000) {
				try {
					callback()
				} catch(e) {
					logger.error(`Error while executing strict timer ${id}`, e, timer)
				}
			}
		}
	})
	if (timers.size === 0) {
		stopStrictTimers()
	} else if (typeof checkTimersId !== 'undefined') {
		checkTimersId = window.setTimeout(checkTimers, checkTimersInterval)
	}
}

export let clearStrictTimeout = (id: number) => {
	timers.delete(id)
}

export let setStrictTimeout = (callback: () => any, delay: number, limit?: number) => {
	if (typeof checkTimersId === 'undefined') {
		startStrictTimers()
	}
	let now = new Date().getTime()
	let start = now + delay
	let end = limit ? start + limit : undefined
	let id = nextId++
	timers.set(id, { start, end, callback })
	return id
}

export let startStrictTimers = () => {
	if (typeof checkTimersId === 'undefined') {
		checkTimersId = window.setTimeout(checkTimers, checkTimersInterval)
	}
}

export let stopStrictTimers = () => {
	checkTimersId = undefined
	window.clearTimeout(checkTimersId)
}
