/*
Create a named database that uses the browser's Cache API, with a fallback to IndexedDB.
*/

import { createCacheStorageDatabase } from "./cacheStorageDatabase"
import { createLocalForageDatabase } from "./localForageDatabase"
import { iDatabase } from "./types"
import log from 'modules-core/log'

const logger = log.getLogger('storageDatabase')

export const createStorageDatabase = (name = 'database'): iDatabase => {
	let database: iDatabase

	// Use IndexedDB, fallback to Cache API, which raises an "operation too large" error
	// when we have too many (>28k) keys.
	// See: https://bugs.chromium.org/p/chromium/issues/detail?id=688268#c6
	if ('indexedDB' in self) {
		logger.info(`Using IndexedDB for cache database: ${name}`)
		database = createLocalForageDatabase(name)
		if ('caches' in self) {
			createCacheStorageDatabase(name).clear()
		}
	} else {
		logger.info(`Using Cache API for storage database: ${name}`)
		database = createCacheStorageDatabase(name)
		// If IndexedDB is not available, we can't open and then clear a Cache API database.
		// createLocalForageDatabase(name).clear()
	}
	return database
}

export const storageDatabase = createStorageDatabase()
export default storageDatabase
