import { createAudioEffect } from '.'
import { createDefaultEffectParams } from '../constants'
import { AudioEffect } from '../types'
import config from 'modules-core/config'

const notEmpty = (value) => value !== 0

export const createAnalyserEffect = (params: AudioEffect.AnalyserEffectParams = {}) => {
	params = Object.assign(createDefaultEffectParams('analyser'), params)
	if (params.isDestination)
		params.output = undefined

	// TODO: Use destructuring assignment for `params`?
	const node = new AnalyserNode(config.audioContext, params)

	let visualisationRequestID: number
	let visualisationDrawFunction: (data: AudioEffect.AnalyserData) => boolean

	let lastFrequencyData: AudioEffect.FrequencyData
	let lastTimeDomainData: AudioEffect.TimeDomainData

	const effect: AudioEffect.AnalyserEffect = {
		params,
		setParam: {
			minDecibels: val => node.minDecibels = val,
			maxDecibels: val => node.maxDecibels = val,
			smoothingTimeConstant: val => node.smoothingTimeConstant = val,
			fftSize: val => node.fftSize = val,
		},
		...createAudioEffect(node),
		getData: () => {
			return {
				...effect.getFrequencyData(),
				...effect.getTimeDomainData(),
			}
		},
		getFrequencyData: () => {
			const frequencyData = new Uint8Array(node.frequencyBinCount)
			node.getByteFrequencyData(frequencyData)
			lastFrequencyData = {
				frequencyData,
				frequencyBinCount: node.frequencyBinCount,
			}
			return lastFrequencyData
		},
		getTimeDomainData: () => {
			const timeDomainData = new Float32Array(node.fftSize)
			node.getFloatTimeDomainData(timeDomainData)
			lastTimeDomainData = {
				timeDomainData,
				fftSize: node.fftSize,
			}
			return lastTimeDomainData
		},
		get isActive () {
			const active: boolean =
				lastFrequencyData?.frequencyData.some(notEmpty) ||
				lastTimeDomainData?.timeDomainData.some(notEmpty)
			return active
		},
		get lastData () {
			return {
				...lastFrequencyData,
				...lastTimeDomainData,
			}
		},
		lastFrequencyData,
		lastTimeDomainData,
		startVisualisation: (drawFunction) => {
			if (drawFunction) {
				visualisationDrawFunction = drawFunction
			}
			// Recursively schedule the given draw function (via `requestAnimationFrame`),
			// with new data from the analyser node.
			const update = () => {
				visualisationRequestID = requestAnimationFrame(update)
				const frequencyData = new Uint8Array(node.frequencyBinCount)
				const timeDomainData = new Float32Array(node.fftSize)
				node.getByteFrequencyData(frequencyData)
				node.getFloatTimeDomainData(timeDomainData)
				visualisationDrawFunction({
					frequencyData,
					timeDomainData,
					fftSize: node.fftSize,
					frequencyBinCount: node.frequencyBinCount,
				})
			}
			// Kick it off.
			requestAnimationFrame(update)
		},
		stopVisualisation: () => {
			cancelAnimationFrame(visualisationRequestID)
			visualisationRequestID = 0
		},
	}
	return effect
}
