


type RecordKey = string | number | symbol

export const createSimpleRecord = <T1 extends RecordKey, T2>(createFunc: (key: T1) => T2) => {

	const record: Partial<Record<T1, T2>> = {}


	return {
		// record,
		getOrUndefined: (key: T1) => record[key],
		getOrCreate: (key: T1) => {
			if (!record[key])
				record[key] = createFunc(key)
			return record[key]
		}
	}
}