import { Func } from './utilTypes'

interface iRandomNormal {
	(): number
}

// See: https://stackoverflow.com/a/18650828
export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes'

	const k = 1000
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

//https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
/* Randomize array in-place using Durstenfeld shuffle algorithm */
export const shuffleArray = (array: any[], randomNormal: iRandomNormal = Math.random) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(randomNormal() * (i + 1))
		const temp = array[i]
		array[i] = array[j]
		array[j] = temp
	}
}


export const randomBetween = (a: number, b: number, randomNormal: iRandomNormal = Math.random) => {
	return a + (b - a) * randomNormal()
}
export const deg2rad = Math.PI / 180
export const rad2deg = 180 / Math.PI
export const degToRad = (deg: number) => deg * deg2rad
export const radToDeg = (deg: number) => deg * rad2deg

export const millis2Secs = 0.001
export const secs2Millis = 1000
export const millisToSecs = (millis: number) => millis * millis2Secs
export const secsToMillis = (secs: number) => secs * secs2Millis


export const millis2Mins = 0.000016667
export const mins2Millis = 60000
export const millisToMins = (millis: number) => millis * millis2Mins
export const minsToMillis = (mins: number) => mins * mins2Millis

export const clamp = (val: number, min: number, max: number) =>
	Math.min(Math.max(val, min), max)

export const polarToCartesian = (theta: number, radius = 1) => ({
	x: Math.cos(theta) * radius,
	y: Math.sin(theta) * radius
})

export const length = (x, y) => Math.sqrt(x * x + y * y)

export const cartesianToPolar = (x: number, y: number) => ({
	theta: Math.atan2(y, x),
	radius: length(x, y)
})


export const isAlmostEqual = (a: number, b: number, delta = 0.0001) => Math.abs(b - a) < delta


export const normalUnsignedToSigned = (val: number) => val * 2 - 1
export const normalSignedToUnsigned = (val: number) => val * 0.5 + 1


export const lerp = (a: number, b: number, t: number) => a + (b - a) * t


export const fromLength = <T>(create: Func<number, T> | Func<void, T>, length: number) => {
	const arr = new Array<T>(length)
	for (let i = 0; i < arr.length; i++) {
		arr[i] = (create as any)(i)
	}
	return arr
}

export const randomIndex = <T>(arr: T[], randomFunc = Math.random) =>
	arr[Math.floor(randomFunc() * arr.length)]


export const wrapReorder = <T>(arr: T[], index: number) => {
	const next = []
	for (let i = 0; i < arr.length; i++) {
		const i2 = (i + index) % arr.length
		next.push(arr[i2])
	}
	return next
}

export const elementsEqual = <T>(arr1: T[], arr2: T[]) =>
	arr1.length === arr2.length && arr1.every((item, index) => item === arr2[index])

export const createSeededRandom = (seed = 1) => {
	if (seed === 0) seed = 1
	return () => {
		const x = Math.sin(seed++) * 10000
		return x - Math.floor(x)
	}
}

// See: https://stackoverflow.com/a/52649768
/**
 * Computes the recursive average of an indefinite set
 * @param {Iterable<number>} set iterable sequence to average
 * @param {number} initAvg initial average value
 * @param {number} initCount initial average count
 */
export function average(set, initAvg = 0, initCount = 0) {
	if (!set || !set[Symbol.iterator])
		throw Error("must pass an iterable sequence");

	let avg = initAvg;
	let avgCnt = initCount;
	for (let x of set) {
		avgCnt += 1;
		avg = avg * ((avgCnt - 1) / avgCnt) + x / avgCnt;
	}
	return avg; // or {avg: avg, count: avgCnt};
}
