import { Action } from './utilTypes'
import { createUniqueArray } from './uniqueArray'

export interface iEvent<T = void> {
	_listeners: Action<T>[]
	_listenersOneshot: Action<T>[]
	invoke: Action<T>
	addListener: Action<Action<T>>
	addListenerOneshot: Action<Action<T>>
	removeListener: Action<Action<T>>
	removeListenerOneshot: Action<Action<T>>
}



export function createEvent<T = void>(): iEvent<T> {

	const listeners = createUniqueArray<Action<T>>()
	const listenersOneshot = createUniqueArray<Action<T>>()

	const addListener = (listener: Action<T>) => listeners.addItem(listener)
	const addListenerOneshot = (listener: Action<T>) => listenersOneshot.addItem(listener)

	const removeListener = (listener: Action<T>) => listeners.removeItem(listener)
	const removeListenerOneshot = (listener: Action<T>) => listenersOneshot.removeItem(listener)

	function invoke(args?: any) {
		listeners
			.getItems()
			.forEach(listener => listener(args))
		listenersOneshot
			.getItems()
			.forEach(listener => listener(args))
		listenersOneshot.clearItems()
	}

	return {
		_listeners: listeners._items,
		_listenersOneshot: listenersOneshot._items,
		invoke,
		addListener,
		addListenerOneshot,
		removeListener,
		removeListenerOneshot
	}


}