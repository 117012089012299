/*
An LRU composite database that uses one database for reads and writes (e.g.
`storageDatabase`, which uses the Cache API or IndexedDB for local storage), with a
fallback to a second database on read miss (e.g one that makes a fetch request to S3).

Keeps track of the last access time (via `getItem`) we can remove least recently used
data when necessary to keep storage use under some limit.

For now, we are assuming that the first database is relying on browser storage and so we
are using the browser storage limit (TODO: or a user-defined limit) to decide when we
need to remove least recently used data.
*/

import createLruCache, { fromJSON } from './lruCache'
import { iDatabase } from './types'
import { getServerTime } from 'modules-core/utility'
import log from 'modules-core/log'
import { iCache } from '.'
import { formatBytes } from 'modules-core/utility/MathExt'
import { oneLine, source } from 'common-tags'

const logger = log.getLogger('compositeDatabase')

// Maintain a storage buffer of 50 MB, so we have enough room to add that much data in
// one hit without exceeding our storage quota. This can happen if we call many async
// functions that load data in a fast loop. This buffer should also be large enough to
// allow anything we might save directly via `storageDatabase`, which will bypass the
// composite database LRU policy.
const storageBuffer = 50 * 1000 * 1000

interface iDbLruCache {
	size: number
	timestamp: number
}

export const createCompositeDatabase = (prioritizedDatabases: iDatabase[]) => {
	const forEachDatabase = async (func: (db: iDatabase) => void) => {
		await Promise.all(prioritizedDatabases.map(func))
	}

	const forEachDatabasePartial = async (
		func: (db: iDatabase) => void,
		startIndex: number,
		endIndex: number
	) => {
		const promises = prioritizedDatabases.slice(startIndex, endIndex).map(func)
		await Promise.all(promises)
	}

	let lruCache: iCache<string, iDbLruCache>

	const lruCacheKey = 'lruCacheBackup'

	const lruEvict = async (target: number) => {
		let toRemove: number
		if (navigator.storage?.estimate) {
			const estimate = await navigator.storage.estimate()
			const overrun = estimate.usage - estimate.quota
			toRemove = target + overrun
			if (toRemove < 0) {
				// Quota available is already more than the target. Nothing to do.
				return
			}
			let removed = 0
			let i = 0
			while (removed < toRemove) {
				const removedItem = lruCache.removeOne()
				if (removedItem) {
					logger.debug(`Removed LRU item: ${removedItem.id}`)
				} else {
					logger.debug(`Nothing left in LRU cache to remove.`)
					break
				}
				await db.removeItem(removedItem.id)
				removed += removedItem.data.size
				i += 1
			}
			if (i > 0) {
				logger.warn(source`
					Removed ${i} LRU items from cache database to free ${formatBytes(removed)}.
						storage limit: ${formatBytes(estimate.quota)}
						storage used: ${oneLine`
							${formatBytes(estimate.usage)}
							(${(estimate.usage / estimate.quota * 100).toFixed(2)}%)
						`}
				`)
			}
		}
	}

	const db: iDatabase = {
		getItem: async (key) => {
			for (let i = 0; i < prioritizedDatabases.length; i++) {
				const item = await prioritizedDatabases[i].getItem(key)
				if (item) {
					forEachDatabasePartial(async (db) => {
						await lruEvict(storageBuffer)
						await db.setItem(key, item)
						lruCache.set(key, { size: item.size, timestamp: getServerTime() })
					}, 0, i)
					return item
				}
			}
			return null
		},
		setItem: (key, item) => { throw new Error('Not implemented.') },
		removeItem: async (key) => {
			await forEachDatabase(async (db) => await db.removeItem(key))
			lruCache.removeByKey(key)
		},
		clear: async () => {
			await forEachDatabase((db) => db.clear())
			lruCache.clear()
		},
		getKeys: async () => {
			const keys = new Set<string>()
			await forEachDatabase(async (db) => (await db.getKeys()).forEach((key) => keys.add(key)))
			return Array.from(keys)
		}
	}

	const init = async () => {
		// Get or create LRU cache.
		const getLruCache = async () => {
			const blob = await prioritizedDatabases[0].getItem(lruCacheKey)
			if (blob === null) {
				logger.info('Create new LRU cache.')
				return createLruCache<string, iDbLruCache>()
			}
			logger.info('Restore LRU cache from backup.')
			return fromJSON(await new Response(blob).text())
		}
		lruCache = await getLruCache()

		// Add keys we have already in the cache but not the lruCache, to the lruCache.
		const syncLruCacheKeys = async () => {
			const lruKeys = lruCache.keys()
			const cacheKeys = await db.getKeys()
			const missingKeys = cacheKeys.filter((key) => {
				return key !== lruCacheKey && !lruKeys.includes(key)
			})
			missingKeys.forEach(async (key) => {
				const item = await db.getItem(key)
				lruCache.set(key, { size: item.size, timestamp: getServerTime() })
				logger.debug(source`
					Restored item found in cache database but missing from LRU cache.
						key: ${key}
				`)
			})
			if (missingKeys.length) {
				logger.info(oneLine`
					Restored ${missingKeys.length} items found in cache database but missing from
					LRU cache.
				`)
			}
		}
		syncLruCacheKeys()

		// Backup LRU cache every 5 seconds, if it has changed.
		const backupLruCache = async () => {
			if (lruCache.isDirty) {
				const db = prioritizedDatabases[0]
				const blob = new Blob([lruCache.toJSON()])
				await lruEvict(storageBuffer)
				logger.debug(`LRU cache is dirty. Backup. Size: ${lruCache.size} items`)
				await db.setItem(lruCacheKey, blob)
				lruCache.setDirty(false)
			}
		}
		logger.info("Call backupLruCache() every 5 seconds.")
		window.setInterval(backupLruCache, 5000)
	}
	init()

	return db
}
