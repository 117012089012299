import { iEvent, createEvent } from 'modules-core/utility'
import { createSimpleRecord } from 'modules-core/utility/simpleRecord'
import config from 'modules-core/config'
import log from 'modules-core/log'
import syrinscape from 'app/common/syrinscape'

const logger = log.getLogger('postMessageSystem')

export interface iMessage {
	message: string
	params?: any
	token?: string
}

export const createPostMessageSystem = () => {
	logger.info("createPostMessageSystem()")
	const onMessage = createSimpleRecord<string, iEvent<iMessage>>(createEvent)
	const onAnyMessage = createEvent<iMessage>()
	window.addEventListener('message', event => {
		let { message, params, token } = event.data as iMessage
		if (token !== config.postMessageToken) {
			return
		}
		let msg = { message, params }
		onAnyMessage.invoke(msg)
		onMessage.getOrUndefined(msg.message)?.invoke(msg)
	})

	const postMessageSystem = {
		onMessage: (message: string) => onMessage.getOrCreate(message),
		onAnyMessage,
		sendMessage: (message: string, params?: any) => {
			if (!config.postMessageWindow) {
				return
			}
			// params = JSON.parse(JSON.stringify(params))
			let msg = { message, params, token: config.postMessageToken }
			config.postMessageWindow.postMessage(msg, '*')
		}
	}

	postMessageSystem.onMessage('syrinscape.setToken').addListener(
		(msg: iMessage) => { config.token = msg.params.token }
	)

	return postMessageSystem
}

export type iPostMessageSystem = ReturnType<typeof createPostMessageSystem>

syrinscape._r.postMessageSystem ??= createPostMessageSystem()

export const postMessageSystem = syrinscape._r.postMessageSystem
export default postMessageSystem
