/**
 * Adapted from https://github.com/tusharf5/runtime-memcache
 */

import { LRULinkedList } from './lruLinkedList'
import { iCache } from './types'
import { cleanKey } from './utility'
import log from 'modules-core/log'

const logger = log.getLogger('lruCache')

const limit = Infinity

function createLruCache<K, V>(): iCache<K, V> {
	const cache = new LRULinkedList<K, V>(limit)

	let _dirty = false

	const setDirty = (value) => {
		if (_dirty !== value && value) {
			logger.debug('Set LRU cache dirty.')
		}
		_dirty = value
	}

	function get(key: K) {
		const val = cache.get(cleanKey(key as any) as any)

		if (val) {
			return val
		}

		return null
	}

	function set(key: K, value: any) {
		setDirty(true)
		cache.addNodeToHead(cleanKey(key as any) as any, value)

		return true
	}

	function removeByCount(number: number) {
		setDirty(true)
		cache.removeNodes(number)

		return true
	}

	function removeByKey(key: K) {
		setDirty(true)
		return cache.remove(cleanKey(key as any) as any)
	}

	function removeOne() {
		setDirty(true)
		return cache.removeTailNode()
	}

	function has(key: K) {
		return cache.has(cleanKey(key as any) as any)
	}

	function size(): number {
		return cache.size
	}

	function keys(): K[] {
		return cache.keys()
	}

	function clear() {
		setDirty(true)
		const limit = cache.limit
		cache.limit = 0
		cache.size = 0 // Setter will remove excess nodes (> limit)
		cache.limit = limit
	}

	function toJSON() {
		if (cache.size === 0) {
			return JSON.stringify([])
		}
		const json = []
		let node = cache.tail
		while (node) {
			json.push({
				id: node.id,
				data: node.data,
			})
			node = node.prev
		}
		return JSON.stringify(json)
	}

	return {
		get isDirty() {
			return _dirty
		},
		setDirty,
		cache,
		get,
		set,
		removeByCount,
		removeByKey,
		removeOne,
		has,
		size,
		keys,
		clear,
		toJSON,
	}
}
interface iDbLruCache {
	size: number
	timestamp: number
}

export function fromJSON(json: string) {
	const newCache = createLruCache<string, iDbLruCache>()
	JSON.parse(json).forEach(node => {
		newCache.set(node.id, node.data)
	})
	return newCache
}

export default createLruCache
