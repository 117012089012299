import { iMessage, postMessageSystem } from "modules-core/postMessageSystem"
import log from "modules-core/log"
import syrinscape from "app/common/syrinscape"

const logger = log.getLogger('eventSystem')

class SyrinscapeEvent {
	eventName: string
	listeners: (EventListenerOrEventListenerObject)[]

	constructor(name: string, listener?: EventListenerOrEventListenerObject) {
		this.listeners = []
		if (listener) {
			this.listeners.push(listener)
		}
		this.eventName = `syrinscape.${name}`
	}

	addListener(listener: EventListenerOrEventListenerObject) {
		this.listeners.push(listener)
		document.addEventListener(this.eventName, listener)
		// Return unbind function.
		return () => {
			document.removeEventListener(this.eventName, listener)
		}
	}

	delete() {
		this.listeners.forEach((listener) => {
			document.removeEventListener(this.eventName, listener)
		})
		delete events[this.eventName]
	}

	dispatch(detail?: any) {
		const event = new CustomEvent(this.eventName, {
			detail: detail ?? null,
		})
		document.dispatchEvent(event)
		postMessageSystem.sendMessage('syrinscape.dispatchEvent', {
			eventName: this.eventName,
			eventDetail: detail,
		})
	}
}

interface SyrinscapeEvents {
	[key: string]: SyrinscapeEvent
}

// Add events to global syrinscape object and export for other modules.
syrinscape.events ??= {} as SyrinscapeEvents
export const events: SyrinscapeEvents = syrinscape.events

const createEventSystem = () => {
	logger.info("createEventSystem()")

	postMessageSystem.onMessage('syrinscape.dispatchEvent').addListener(
		(msg: iMessage) => {
			const event = new CustomEvent(msg.params.eventName, {
				detail: msg.params.eventDetail || null,
			})
			document.dispatchEvent(event)
		}
	)

	let getOrCreate = (eventName: string) => {
		let event = events[eventName]
		if (typeof event === 'undefined') {
			events[eventName] = new SyrinscapeEvent(eventName)
		}
		return events[eventName]
	}

	return {
		add: (eventName: string, listener?: EventListenerOrEventListenerObject) => {
			const event = getOrCreate(eventName)
			if (listener !== undefined) {
				return event.addListener(listener)
			}
			return () => {}
		},

		delete: (eventName: string) => {
			if (!events[eventName]) {
				return
			}
			events[eventName].delete()
		},

		dispatch: (eventName: string, detail?: any) => {
			getOrCreate(eventName).dispatch(detail)
		},
	}
}

export type EventSystem = ReturnType<typeof createEventSystem>

syrinscape._r.eventSystem ??= createEventSystem()

export const eventSystem = syrinscape._r.eventSystem
export default eventSystem

// Register events. Usually this happens in the module where the event is dispatched or
// handled, but we need to register some here to avoid circular imports.
eventSystem.add("updateConfig")
