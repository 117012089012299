import { createEvent, iEvent } from './event'



export const createMutableMap = <T1, T2>(): iMutableMap<T1, T2> => {
	const map = new Map<T1, T2>()
	const onUpdate = createEvent<Readonly<Map<T1, T2>>>()

	return {
		getRaw: () => map,
		get: () => map as Readonly<Map<T1, T2>>,
		update: () => onUpdate.invoke(map),
		onUpdate,
		mutate: (action) => {
			action(map)
			onUpdate.invoke(map)
		},
		tryMutate: (action) => {
			if (action(map))
				onUpdate.invoke(map)
		},
	}
}


export interface iMutableMap<T1, T2> {
	get: () => Readonly<Map<T1, T2>>
	getRaw: () => Map<T1, T2>
	update: () => void
	onUpdate: iEvent<Readonly<Map<T1, T2>>>
	mutate: (action: (map: Map<T1, T2>) => any) => void
	tryMutate: (action: (map: Map<T1, T2>) => boolean) => void
}
// export type iMutableMap = ReturnType<typeof createMutableMap>