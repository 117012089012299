export const wait = (millis: number) =>
	new Promise(resolve => setTimeout(resolve, millis))

export const waitForRequestAnimationFrame = () =>
	new Promise((resolve) => window.requestAnimationFrame(resolve))


export function runWithTimeout(promise: Promise<any>, timeout = 8000) {
	return new Promise((resolve, reject) => {
		const timer = setTimeout(() => {
			reject(new Error('TIMEOUT'))
		}, timeout)
		promise.then(val => {
			clearTimeout(timer)
			resolve(val)
		}).catch(err => {
			clearTimeout(timer)
			reject(err)
		})
	})
}

export function createFetchWithTimeout(fetch = window.fetch, timeout?) {
	return (url, opts = {}) => runWithTimeout(fetch(url, opts), timeout) as Promise<Response>
}


// const createDeferred = <T>() => {
//	 let deferred: Partial<Deferred<T>> = {}
//	 deferred.promise = new Promise<T>((resolve, reject) => {
//		 deferred.resolve = resolve
//		 deferred.reject = reject
//	 })
//	 return deferred as Deferred<T>
// }
// export interface Deferred<T> {
//	 resolve: (value: T | PromiseLike<T>) => void
//	 reject: (reason?: any) => void
//	 promise: Promise<T>
// }
