import { blobDatabase } from 'modules-core/cache'
import { createDefaultEffectParams } from '../constants'
import { AudioEffect } from '../types'
import { createAudioEffect } from './createEffect'
import config from 'modules-core/config'


export const createConvolverEffect = async (params: AudioEffect.ConvolverEffectParams = {}) => {
	params = Object.assign(createDefaultEffectParams('convolver'), params)
	const blob = await blobDatabase.getBlob(params.url)
	const arrayBuffer = await blob.arrayBuffer()
	const audioBuffer = await config.audioContext.decodeAudioData(arrayBuffer)

	const node = config.audioContext.createConvolver()
	node.buffer = audioBuffer

	const effect = {
		...createAudioEffect(node),
		params,
		setParam: {}
	} as AudioEffect.ConvolverEffect

	return effect
}
