import { AudioEffect, createAudioEffectSystem } from 'modules-core/audioEffectSystem'
import { iUrlSystem, createUrlSystem } from 'modules-core/urlSystem'
import { FadeOptions } from './types'
import { createBlobSourceInstantiator } from './blobSourceInstantiator'
import { createEvent } from 'modules-core/utility'
import { eventSystem } from 'modules-core/eventSystem'
import { config, maxVolume, minVolume } from 'modules-core/config'
import log from 'modules-core/log'

// Register events.
eventSystem.add('setGlobalVolume')
eventSystem.add('setLocalVolume')

const logger = log.getLogger('audioSystem')

interface Args {
	urlSystem?: iUrlSystem
}
export function createAudioSystem({
	urlSystem,
}: Args = {}) {

	urlSystem = urlSystem || createUrlSystem()

	const effectSystem = createAudioEffectSystem()
	const sourceInstantiator = createBlobSourceInstantiator({ effectSystem })

	const audioSystem = {
		effectSystem,
		effect: effectSystem.effect,
		init: async (useDefaultEffectFile = true) => {
			await effectSystem.init(useDefaultEffectFile)
		},
		setGlobalVolume: (value: number) => {
			value = Number(value)
			if (Number.isNaN(value) || value < minVolume || value > maxVolume) {
				throw Error(`Invalid localVolume: '${value}'`)
			}
			logger.info(`Set global volume: ${value}`)
			effectSystem.globalVolume.fadeToValue(value)
			// Dispatch event so interfaces can update their volume controls.
			eventSystem.dispatch('setGlobalVolume', value)
		},
		setLocalVolume: (value: number) => {
			value = Number(value)
			if (Number.isNaN(value) || value < minVolume || value > maxVolume) {
				throw Error(`Invalid localVolume: '${value}'`)
			}
			logger.info(`Set local volume: ${value}`)
			config.localVolume = value
			effectSystem.localVolume.fadeToValue(value)
			// Dispatch event so interfaces can update their volume controls.
			eventSystem.dispatch('setLocalVolume', value)
		},
		createAudioSource: (src: string, output?: AudioEffect.HasInput, fadeOptions?: FadeOptions, targetVolume?: number) => {
			src = urlSystem.replaceLocalDomainAndPort(src)
			const source = sourceInstantiator.createAudioSource(src, output, fadeOptions, targetVolume)
			return source
		},
		toggleMute() {
			if (config.localVolume === 0) {
				// Muted. Restore last local volume.
				this.setLocalVolume(config.lastLocalVolume)
			} else {
				// Not muted. Set last local volume (to restore on unmute) and mute.
				config.lastLocalVolume = config.localVolume
				this.setLocalVolume(0)
			}
		},
	}
	return audioSystem
}

export type iAudioSystem = ReturnType<typeof createAudioSystem>
