import { createEvent } from 'modules-core/utility'
import { AudioEffect, createVolumeEffect } from 'modules-core/audioEffectSystem'
import { iAudioSystem } from 'modules-core/audioSystem'
import syrinscape from 'app/common/syrinscape'


interface Args {
	audioSystem: iAudioSystem
}
export const createOneshotSystem = ({ audioSystem }: Args) => {
	const { effectSystem } = audioSystem
	let volume = 1
	const onChange = createEvent<number>()
	const system = {
		onChange,
		// get volume() { return volume },
		setVolume: (val: number) => {
			volume = val
			onChange.invoke(volume)
		},
		createOneshotVolume: (
			wet: AudioEffect.VolumeEffect,
			dry: AudioEffect.VolumeEffect,
			dryAnalyser: AudioEffect.AnalyserEffect,
			effect: AudioEffect.Effect
		) => {
			const wetOneshot = createVolumeEffect()
			const dryOneshot = createVolumeEffect()
			wetOneshot.value = volume
			dryOneshot.value = volume
			wet.connect(wetOneshot)
			dry.connect(dryOneshot)
			wetOneshot.connect(effect)
			if (dryAnalyser) {
				dryOneshot.connect(dryAnalyser)
				dryAnalyser.connect(effectSystem.globalVolume)
			} else {
				dryOneshot.connect(effectSystem.globalVolume)
			}
			const handleChange = (volume: number) => {
				wetOneshot.fadeToValue(volume)
				dryOneshot.fadeToValue(volume)
			}
			onChange.addListener(handleChange)
			return () => onChange.removeListener(handleChange)
		}
	}
	return system
}

export type OneshotSystem = ReturnType<typeof createOneshotSystem>
