import { AudioEffect } from '../types'

export const createAudioEffect = (input: AudioNode, output = input) => ({
	input,
	output,
	connect: (other: AudioEffect.HasInput) => output.connect(other.input),
	disconnectAll: () => {
		input.disconnect()
		output.disconnect()
	}
})