import { convolverUrls, stairwellConvolverUrl } from './convolverUrls'
import { AudioEffect } from './types'

export const defaultEffectFileUrl = ''  // 'https://dl.dropboxusercontent.com/s/3uzwlcr5ws12gau/SyrinscapeEffects%20-%20Returning%20to%20testing%20in%20Feb%202022%20-%20Ready%20to%20replace%20on%20server.json?dl=0'

const volumeParam = {
	displayName: 'Volume',
	type: 'number' as const,
	default: 1,
	max: 10
}

const filterParams = {
	detune: {
		displayName: 'Detune',
		type: 'number' as const,
		default: 0,
		min: -153600,
		max: 153600,
		step: 1
	},
	frequency: {
		displayName: 'Frequency',
		type: 'number' as const,
		default: 350,
		min: 0,
		max: 24000,
		step: 100
	},
	resonance: {
		displayName: 'Resonance',
		type: 'number' as const,
		default: 0.5,
	},
	gain: {
		displayName: 'Shelf Gain',
		type: 'number' as const,
		default: 0,
		min: -1541.273681640625,
		max: 1541.273681640625,
	}
}


let defaultNameCount = 0
export const createDefaultEffectParams = <T extends AudioEffect.EffectType>(type: T, name = `My ${type} effect ${++defaultNameCount}`): AudioEffect.EffectParamsTypeMap[T] =>
	Object.entries(effectMetadataMap[type].parameters)
		.reduce((acc, [key, value]) =>
			({ ...acc, [key]: JSON.parse(JSON.stringify(value.default)) }),
			{
				name,
				type,
				output: 'Global Volume',
			} as AudioEffect.EffectParamsTypeMap[T])


export const effectMetadataMap: AudioEffect.EffectMetadataMap = {
	'analyser': {
		displayName: 'Analyser',
		parameters: {
			fftSize: {
				displayName: 'fftSize',
				options: {
					'32': '32',
					'64': '64',
					'128': '128',
					'256': '256',
					'512': '512',
					'1024': '1024',
					'2048': '2048',
					'4096': '4096',
					'8192': '8192',
					'16384': '16384',
					'32768': '32768',
				},
				type: 'select',
				default: '128',  // Default: 2048
			},
			maxDecibels: {
				displayName: 'maxDecibels',
				max: 0,
				min: -100,
				step: 1,
				type: 'number',
				default: -30,  // Default: -30
			},
			minDecibels: {
				displayName: 'minDecibels',
				max: 0,
				min: -100,
				step: 1,
				type: 'number',
				default: -100,  // Default: -100
			},
			smoothingTimeConstant: {
				displayName: 'smoothingTimeConstant',
				type: 'number',
				default: 0.8,  // Default: 0.8
			},
			// channelCount,
			// channelCountMode,
			// channelInterpretation,
		},
	},
	'compressor': {
		displayName: 'Compressor',
		parameters: {
			attack: {
				displayName: 'attack',
				step: 0.001,
				type: 'number',
				default: 0.003,  // Default 0.003
			},
			knee: {
				displayName: 'knee',
				max: 40,
				min: 0,
				step: 1,
				type: 'number',
				default: 30,  // Default 30
			},
			ratio: {
				displayName: 'ratio',
				max: 20,
				min: 1,
				step: 1,
				type: 'number',
				default: 12,  // Default 12
			},
			release: {
				displayName: 'release',
				type: 'number',
				default: 0.25,  // Default 0.25
			},
			threshold: {
				displayName: 'threshold',
				max: 0,
				min: -100,
				step: 1,
				type: 'number',
				default: -24,  // Default: -24
			},
		},
	},
	'convolver': {
		displayName: 'Convolver',
		parameters: {
			url: {
				displayName: 'URL',
				// type: 'select',
				type: 'string',
				default: stairwellConvolverUrl,
				// options: convolverUrls
			},
		}
	},
	'delay': {
		displayName: 'Delay',
		parameters: {
			falloff: {
				displayName: 'Falloff',
				type: 'number',
				default: 0.5,
			},
			duration: {
				displayName: 'Duration',
				type: 'number',
				default: 0.5,
				max: 1,
			}
		}
	},
	'send': {
		displayName: 'Send',
		parameters: {
			wetness: {
				displayName: 'Wetness',
				type: 'number',
				default: 0.5
			},
			dryOutput: {
				displayName: 'Dry Output',
				type: 'audioReceive',
				default: 'Global Volume'
			}
		}
	},
	'volume': {
		displayName: 'Volume',
		parameters: {
			volume: volumeParam,
			fadeDuration: {
				displayName: 'Fade Duration',
				type: 'number',
				default: 1,
				min: 0,
				max: 5,
			}
		}
	},
	'spatial': {
		displayName: 'Spatial',
		parameters: {
			position: {
				displayName: 'Position',
				type: 'vector3',
				default: {
					x: 0,
					y: 0,
					z: 0
				}
			},
			speed: {
				displayName: 'Speed',
				default: 0,
				type: 'number',
				max: 1000
			},
			panningModel: {
				displayName: 'Panning Model',
				type: 'select',
				// Do not use equalpower. It will only send audio to the closest speaker, e.g.
				// all sound to just the right channel if the source is on the right.
				default: 'HRTF',
				options: {
					'Head Related TF': 'HRTF',
					'Equal Power': 'equalpower'
				} as Record<string, PanningModelType>
			},
			distanceModel: {
				displayName: 'Distance Model',
				type: 'select',
				default: 'inverse',
				options: {
					'Inverse': 'inverse',
					'Exponential': 'exponential',
					'Linear': 'linear'
				} as Record<string, DistanceModelType>
			},
			minDistance: {  // refDistance, see: https://developer.mozilla.org/en-US/docs/Web/API/PannerNode/refDistance
				displayName: 'Min Distance',
				type: 'number',
				default: 0,
				max: 1000,
			},
			maxDistance: {
				displayName: 'Max Distance',
				type: 'number',
				default: 100,
				max: 1000,
			},
			rolloff: {
				displayName: 'Rolloff',
				type: 'number',
				default: 0.5,
				max: 100,
			}
		}
	},
	'filter': {
		displayName: 'Filter',
		parameters: {
			...filterParams,
			filterType: {
				displayName: 'Filter Type',
				type: 'select',
				default: 'lowpass',
				options: {
					'Low Pass': 'lowpass',
					'High Pass': 'highpass',
					'Band Pass': 'bandpass',
					'All Pass': 'allpass',
					'Low Shelf': 'lowshelf',
					'High Shelf': 'highshelf',
					'Peaking': 'peaking',
					'Notch': 'notch',
				} as Record<string, BiquadFilterType>
			}
		}
	},
	'reverb': {
		displayName: 'Reverb',
		parameters: {
			duration: {
				displayName: 'Duration',
				type: 'number',
				default: 1,
				max: 5
			},
			preDelay: {
				displayName: 'Pre Delay',
				type: 'number',
				default: 0.03,
				max: 1
			},
			attack: {
				displayName: 'Attack',
				step: 0.001,
				type: 'number',
				default: 0.001,
			},
			decay: {
				displayName: 'Decay',
				type: 'number',
				default: 0.1,
				max: 1,
			},
			lpCutoff: {
				...filterParams.frequency,
				displayName: 'LP Cutoff',
				default: 2000,
			},
			lpResonance: {
				displayName: 'LP Resonance',
				type: 'number',
				default: 0.2,
				max: 1
			},
			hpCutoff: {
				...filterParams.frequency,
				displayName: 'HP Cutoff',
				default: 500,
			},
			hpResonance: {
				displayName: 'HP Resonance',
				type: 'number',
				default: 0.1,
				max: 1
			}
		}
	},
	'freeverb': {
		displayName: 'Freeverb',
		parameters: {
			roomSize: {
				displayName: 'Room Size',
				type: 'number',
				default: 0.9,
				max: 1
			},
			dampening: {
				displayName: 'Dampening',
				type: 'number',
				default: 6000,
				step: 20,
				max: 20000
			},
			preDelay: {
				displayName: 'Predelay',
				type: 'number',
				default: 0.01,
				max: 1
			},
			lpFrequency: {
				...filterParams.frequency,
				displayName: 'LP Cutoff',
				default: 2000
			},
			hpFrequency: {
				...filterParams.frequency,
				displayName: 'HP Cutoff',
				default: 600
			},
			lpResonance: {
				...filterParams.resonance,
				displayName: 'LP Resonance',
			},
			// lpGain:{
			//	 ...filterParams.gain,
			//	 displayName:'LP Gain'
			// },
			hpResonance: {
				...filterParams.resonance,
				displayName: 'HP Resonance'
			},
			gain: {
				...volumeParam,
				displayName: 'Gain',
				default: 0.2
			}
			// hpGain:{
			//	 ...filterParams.gain,
			//	 displayName:'HP Gain'
			// },
		}
	}
}

