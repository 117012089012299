import { iVector3 } from 'modules-core/utility'
import { createDefaultEffectParams } from '../constants'
import { AudioEffect } from '../types'
import { createAudioEffect } from './createEffect'
import config from 'modules-core/config'

export const createSpatialEffect = (params: AudioEffect.SpatialEffectParams = {}) => {
	params = Object.assign(createDefaultEffectParams('spatial'), params)

	const panner = new PannerNode(config.audioContext, {
		panningModel: params.panningModel,
		distanceModel: params.distanceModel,
		rolloffFactor: params.rolloff,
		refDistance: params.minDistance,
		maxDistance: params.maxDistance,
	})

	const effect = {
		params,
		...createAudioEffect(panner),
		setPosition: (vec: iVector3) => {
			panner.positionX.value = vec.x || 0
			panner.positionY.value = vec.y || 0
			panner.positionZ.value = vec.z || 0
		},
		getPosition: () => ({ x: panner.positionX.value, y: panner.positionY.value, z: panner.positionZ.value }),
		// `PannerNode.setVelocity()` is deprecated. We have re-implemented it here by
		// applying a vector (expressed in meters per second) to the panning node's position
		// over the duration of a sample to get its final destination, and then applying a
		// linear ramp to each axis.
		setVelocity: function (vector: iVector3, seconds: number) {
			panner.positionX.linearRampToValueAtTime(
				panner.positionX.value + vector.x * seconds,
				config.audioContext.currentTime + seconds,
			)
			panner.positionY.linearRampToValueAtTime(
				panner.positionY.value + vector.y * seconds,
				config.audioContext.currentTime + seconds,
			)
			panner.positionZ.linearRampToValueAtTime(
				panner.positionZ.value + vector.z * seconds,
				config.audioContext.currentTime + seconds,
			)
		},
		setParam: {
			'position': (val) => effect.setPosition(val)
		},
		node: panner
	} as AudioEffect.SpatialEffect

	effect.setPosition(params.position)

	return effect
}
