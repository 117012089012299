//https://blog.gskinner.com/archives/2019/02/reverb-web-audio-api.html

// SAFARI Polyfills
// if(!window.AudioBuffer.prototype.copyToChannel) {
// 	window.AudioBuffer.prototype.copyToChannel = function copyToChannel (buffer,channel) {
// 		this.getChannelData(channel).set(buffer)
// 	}
// }
// if(!window.AudioBuffer.prototype.copyFromChannel) {
// 	window.AudioBuffer.prototype.copyFromChannel = function copyFromChannel (buffer,channel) {
// 		buffer.set(this.getChannelData(channel))
// 	}
// }
// var OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext
class SkinnerEffect {
	name: string
	context: AudioContext
	input: AudioNode
	effect: AudioNode
	bypassed: boolean
	output: AudioNode
	constructor(context: AudioContext) {
		this.name = 'effect'
		this.context = context
		this.input = this.context.createGain()
		this.effect = null
		this.bypassed = false
		this.output = this.context.createGain()
		this.setup()
		this.wireUp()
	}

	setup() {
		this.effect = this.context.createGain()
	}

	wireUp() {
		this.input.connect(this.effect)
		this.effect.connect(this.output)
	}

	connect(destination) {
		this.output.connect(destination)
	}

}

// class SkinnerSample {
//	 context: AudioContext
//	 buffer: AudioBufferSourceNode
//	 sampleBuffer: AudioBuffer
//	 loaded: boolean
//	 output: GainNode

//	 constructor(context: AudioContext) {
//		 this.context = context
//		 this.buffer = this.context.createBufferSource()
//		 this.buffer.start()
//		 this.sampleBuffer = null
//		 // this.rawBuffer = null
//		 this.loaded = false
//		 this.output = this.context.createGain()
//		 this.output.gain.value = 0.1
//	 }

//	 play() {
//		 if (this.loaded) {
//			 this.buffer = this.context.createBufferSource()
//			 this.buffer.buffer = this.sampleBuffer
//			 this.buffer.connect(this.output)
//			 this.buffer.start(this.context.currentTime)
//		 }
//	 }

//	 connect(input) {
//		 this.output.connect(input)
//	 }

//	 async load(path) {
//		 this.loaded = false
//		 const response = await fetch(path)
//		 const blob = await response.arrayBuffer()
//		 this.sampleBuffer = await this.context.decodeAudioData(blob)
//		 this.loaded = true
//		 return this
//	 }
// }


class SkinnerAmpEnvelope {
	context: AudioContext
	output: GainNode
	partials: []
	velocity: number
	gain: number
	_attack: number
	_decay: number
	_sustain: number
	_release: number

	constructor(context: AudioContext, gain = 1) {
		this.context = context
		this.output = this.context.createGain()
		this.output.gain.value = gain
		this.partials = []
		this.velocity = 0
		this.gain = gain
		this._attack = 0
		this._decay = 0.001
		this._sustain = this.output.gain.value
		this._release = 0.001
	}

	on(velocity) {
		this.velocity = velocity / 127
		this.start(this.context.currentTime)
	}

	off(MidiEvent) {
		return this.stop(this.context.currentTime)
	}

	start(time) {
		this.output.gain.value = 0
		this.output.gain.setValueAtTime(0, time)
		this.output.gain.setTargetAtTime(1, time, this.attack + 0.00001)
		this.output.gain.setTargetAtTime(this.sustain * this.velocity, time + this.attack, this.decay)
	}

	stop(time) {
		this.sustain = this.output.gain.value
		this.output.gain.cancelScheduledValues(time)
		this.output.gain.setValueAtTime(this.sustain, time)
		this.output.gain.setTargetAtTime(0, time, this.release + 0.00001)
	}

	set attack(value) {
		this._attack = value
	}

	get attack() {
		return this._attack
	}

	set decay(value) {
		this._decay = value
	}

	get decay() {
		return this._decay
	}

	set sustain(value) {
		this.gain = value
		this._sustain
	}

	get sustain() {
		return this.gain
	}

	set release(value) {
		this._release = value
	}

	get release() {
		return this._release
	}

	connect(destination) {
		this.output.connect(destination)
	}
}

class SkinnerVoice {
	context: AudioContext
	type: OscillatorType
	value: number
	gain: number
	output: GainNode
	partials: OscillatorNode[]
	ampEnvelope: SkinnerAmpEnvelope

	constructor(context: AudioContext, type: OscillatorType = 'sawtooth', gain = 0.1) {
		this.context = context
		this.type = type
		this.value = -1
		this.gain = gain
		this.output = this.context.createGain()
		this.partials = []
		this.output.gain.value = this.gain
		this.ampEnvelope = new SkinnerAmpEnvelope(this.context)
		this.ampEnvelope.connect(this.output)
	}

	init() {
		const osc = this.context.createOscillator()
		osc.type = this.type
		osc.connect(this.ampEnvelope.output)
		osc.start(this.context.currentTime)
		this.partials.push(osc)
	}

	on(MidiEvent) {
		this.value = MidiEvent.value
		this.partials.forEach((osc) => {
			osc.frequency.value = MidiEvent.frequency
		})
		this.ampEnvelope.on(MidiEvent.velocity || MidiEvent)
	}

	off(MidiEvent?) {
		this.ampEnvelope.off(MidiEvent)
		this.partials.forEach((osc) => {
			osc.stop(this.context.currentTime + this.ampEnvelope.release * 4)
		})
	}

	connect(destination) {
		this.output.connect(destination)
	}

	set detune(value) {
		this.partials.forEach(p => p.detune.value = value)
	}

	set attack(value) {
		this.ampEnvelope.attack = value
	}

	get attack() {
		return this.ampEnvelope.attack
	}

	set decay(value) {
		this.ampEnvelope.decay = value
	}

	get decay() {
		return this.ampEnvelope.decay
	}

	set sustain(value) {
		this.ampEnvelope.sustain = value
	}

	get sustain() {
		return this.ampEnvelope.sustain
	}

	set release(value) {
		this.ampEnvelope.release = value
	}

	get release() {
		return this.ampEnvelope.release
	}

}
class SkinnerNoise extends SkinnerVoice {
	_length: number
	//@ts-ignore
	partials: AudioBufferSourceNode[]
	constructor(context, gain) {
		super(context, gain)
		this._length = 2
	}

	get length() {
		return this._length || 2
	}
	set length(value) {
		this._length = value
	}

	init() {
		const lBuffer = new Float32Array(this.length * this.context.sampleRate)
		const rBuffer = new Float32Array(this.length * this.context.sampleRate)
		for (let i = 0; i < this.length * this.context.sampleRate; i++) {
			lBuffer[i] = 1 - (2 * Math.random())
			rBuffer[i] = 1 - (2 * Math.random())
		}
		const buffer = this.context.createBuffer(2, this.length * this.context.sampleRate, this.context.sampleRate)
		buffer.copyToChannel(lBuffer, 0)
		buffer.copyToChannel(rBuffer, 1)

		const osc = this.context.createBufferSource()
		osc.buffer = buffer
		osc.loop = true
		osc.loopStart = 0
		osc.loopEnd = 2
		osc.start(this.context.currentTime)
		osc.connect(this.ampEnvelope.output)
		this.partials.push(osc)
	}

	on(MidiEvent) {
		this.value = MidiEvent.value
		this.ampEnvelope.on(MidiEvent.velocity || MidiEvent)
	}

}

class SkinnerFilter extends SkinnerEffect {
	effect: BiquadFilterNode
	constructor(context, type: BiquadFilterType = 'lowpass', cutoff = 1000, resonance = 0.9) {
		super(context)
		this.name = 'filter'
		this.effect.frequency.value = cutoff
		this.effect.Q.value = resonance
		this.effect.type = type
	}

	setup() {
		this.effect = this.context.createBiquadFilter()
		this.effect.connect(this.output)
		this.wireUp()
	}

}

export class SkinnerAdvancedReverb extends SkinnerEffect {

	preDelay: DelayNode
	multitap: DelayNode[]
	multitapGain: GainNode

	duration: number
	attack: number
	decay: number
	release: number
	wet: GainNode
	effect: ConvolverNode

	constructor(context: AudioContext) {
		super(context)
		this.name = 'AdvancedReverb'
	}

	//@ts-ignore
	override setup({ duration, preDelay, attack, decay }: {
		duration?: number
		preDelay?: number
		attack?: number
		decay?: number
	} = { }) {
		duration ??= 1
		preDelay ??= 0.03
		attack ??= 0.001
		decay ??= 0.1

		this.effect = this.context.createConvolver()

		this.duration = duration
		this.attack = attack
		this.decay = decay
		this.release = duration

		this.preDelay = this.context.createDelay(duration)
		this.preDelay.delayTime.setValueAtTime(preDelay, this.context.currentTime)

		this.multitap = []

		for (let i = 2; i > 0; i--) {
			this.multitap.push(this.context.createDelay(duration))
		}
		this.multitap.map((t, i) => {
			if (this.multitap[i + 1]) {
				t.connect(this.multitap[i + 1])
			}
			t.delayTime.setValueAtTime(0.001 + (i * (preDelay / 2)), this.context.currentTime)
		})

		this.multitapGain = this.context.createGain()
		this.multitap[this.multitap.length - 1].connect(this.multitapGain)

		this.multitapGain.gain.value = 0.2

		this.multitapGain.connect(this.output)

		this.wet = this.context.createGain()
		this.wet.gain.value = 1

		this.input.connect(this.wet)
		this.wet.connect(this.preDelay)
		this.wet.connect(this.multitap[0])
		this.preDelay.connect(this.effect)
		this.effect.connect(this.output)

	}

	renderTail({ lpCutoff, lpResonance, hpCutoff, hpResonance }: {
		lpCutoff?: number
		lpResonance?: number
		hpCutoff?: number
		hpResonance?: number
	} = { }) {
		lpCutoff ??= 2000
		lpResonance ??= 0.2
		hpCutoff ??= 500
		hpResonance ??= 0.1

		const tailContext = new OfflineAudioContext(2, this.context.sampleRate * this.duration, this.context.sampleRate)
		tailContext.oncomplete = (buffer) =>
			this.effect.buffer = buffer.renderedBuffer

		const tailOsc = new SkinnerNoise(tailContext, 1)
		const tailLPFilter = new SkinnerFilter(tailContext, 'lowpass', lpCutoff, lpResonance)
		const tailHPFilter = new SkinnerFilter(tailContext, 'highpass', hpCutoff, hpResonance)

		tailOsc.init()
		tailOsc.connect(tailHPFilter.input)
		tailHPFilter.connect(tailLPFilter.input)
		tailLPFilter.connect(tailContext.destination)
		tailOsc.attack = this.attack
		tailOsc.decay = this.decay
		tailOsc.release = this.release

		tailContext.startRendering()

		tailOsc.on({ frequency: 500, velocity: 1 })
		setTimeout(() => tailOsc.off(), 20)
	}

	set decayTime(value) {
		const dc = value / 3
		this.duration = value
		this.attack = 0
		this.decay = 0
		this.release = dc
		this.renderTail()
	}
}
/*example preset params
{
	"near_reverb_level": -500,
	"name": "Alley",
	"reflections_level": -1204,
	"reflections_delay": 0.007,
	"lf_reference": 250,
	"density": 100,
	"hf_reference": 5000,
	"decay_time": 1.49,
	"far_reverb_level": 2000,
	"reverb_delay": 0.011,
	"room_hf": -270,
	"diffusion": 100,
	"room": -1000,
	"decay_hf_ratio": 0.86,
	"dry_level": 0,
	"far_reverb_distance": 50,
	"near_reverb_distance": 5,
	"room_lf": 0
}
*/