import { SocketSystem } from 'modules-core/socket'
import log from 'modules-core/log'
import { iAuthSystem } from 'modules-core/authSystem'
import { commaLists, oneLine } from 'common-tags'
import { iMessage, postMessageSystem } from 'modules-core/postMessageSystem'

const logger = log.getLogger('controlSystem')

interface Args{
	authSystem: iAuthSystem
	socketSystem: SocketSystem
}

export const ControlSystem = ({ authSystem, socketSystem }: Args) => {

	const sendFull = () => {
		socketSystem.send(
			{ message: 'send_full' }
		)
	}

	const setGlobalVolume = (volume: number) => {
		socketSystem.send(
			{ message: 'set_global_volume', params: { volume: volume } }
		)
	}

	const setOneshotVolume = (volume: number) => {
		socketSystem.send(
			{ message: 'set_oneshot_volume', params: { volume: volume } }
		)
	}

	const startElements = async (pks: (number | string)[]) => {
		// TODO: One request for multiple PKs? If we ever call this with multiple PKs.
		if (pks.length > 1) {
			logger.warn(oneLine`
				startElements(): Starting ${pks.length} elements. This makes a new request for
				each element. Consider refactoring.
			`)
		}
		return await Promise.all(pks.map((pk) => {
			authSystem.authorizedFetchPath(`online/frontend-api/elements/${pk}/play/`)
		}))
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'start_elements', params: { pks: pks } }
		// )
	}

	const startMood = async (pk: number | string) => {
		return await authSystem.authorizedFetchPath(`online/frontend-api/moods/${pk}/play/`)
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'start_mood', params: { pk: pk } }
		// )
	}

	const startSamples = async (pks: (number | string)[]) => {
		// TODO: One request for multiple PKs? If we ever call this with multiple PKs.
		if (pks.length > 1) {
			logger.warn(oneLine`
				startSamples(): Starting ${pks.length} samples. This makes a new request for
				each sample. Consider refactoring.
			`)
		}
		return await Promise.all(pks.map((pk) => {
			authSystem.authorizedFetchPath(`online/frontend-api/samples/${pk}/play/`)
		}))
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'start_samples', params: { pks: pks } }
		// )
	}

	const startPlaylistEntries = async (pks: (number | string)[]) => {
		// TODO: One request for multiple PKs? If we ever call this with multiple PKs.
		if (pks.length > 1) {
			logger.warn(oneLine`
				startPlaylistEntries(): Starting ${pks.length} playlist entries. This makes a new request for
				each element. Consider refactoring.
			`)
		}
		return await Promise.all(pks.map((pk) => {
				authSystem.authorizedFetchPath(`online/frontend-api/playlists/${pk}/play/`)
		}))
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'start_playlist_entries', params: { pks: pks } }
		// )
	}

	const stopAll = async () => {
		return await authSystem.authorizedFetchPath('online/frontend-api/stop-all/')
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'stop_all' }
		// )
	}

	const stopElements = async (pks: (number | string)[]) => {
		// TODO: One request for multiple PKs? If we ever call this with multiple PKs.
		if (pks.length > 1) {
			logger.warn(oneLine`
				stopElements(): Stopping ${pks.length} elements. This makes a new request for
				each element. Consider refactoring.
			`)
		}
		return await Promise.all(pks.map((pk) => {
			authSystem.authorizedFetchPath(`online/frontend-api/elements/${pk}/stop/`)
		}))
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'stop_elements', params: { pks: pks } }
		// )
	}

	const stopMood = async (pk: number | string) => {
		return await authSystem.authorizedFetchPath(`online/frontend-api/moods/${pk}/stop/`)
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'stop_mood', params: { pk: pk } }
		// )
	}

	const stopPlaylistEntries = async (pks: (number | string)[]) => {
		// TODO: One request for multiple PKs? If we ever call this with multiple PKs.
		if (pks.length > 1) {
			logger.warn(oneLine`
				stopPlaylistEntries(): Stopping ${pks.length} elements. This makes a new request
				for each playlist entries. Consider refactoring.
			`)
		}
		return await Promise.all(pks.map((pk) => {
			authSystem.authorizedFetchPath(`online/frontend-api/playlists/${pk}/stop/`)
		}))
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'stop_playlist_entries', params: { pks: pks } }
		// )
	}

	const stopSamples = async (pks: (number | string)[]) => {
		// TODO: One request for multiple PKs? If we ever call this with multiple PKs.
		if (pks.length > 1) {
			logger.warn(oneLine`
				stopSamples(): Stopping ${pks.length} samples. This makes a new request for
				each sample. Consider refactoring.
			`)
		}
		return await Promise.all(pks.map((pk) => {
			authSystem.authorizedFetchPath(`online/frontend-api/samples/${pk}/stop/`)
		}))
		// Websocket implementation (missing authorization checks), in case we refactor.
		// socketSystem.send(
		// 	{ message: 'stop_samples', params: { pks: pks } }
		// )
	}

	postMessageSystem.onMessage('syrinscape.sendFull').addListener(
		(msg: iMessage) => sendFull()
	)
	postMessageSystem.onMessage('syrinscape.setGlobalVolume').addListener(
		(msg: iMessage) => setGlobalVolume(msg.params.volume)
	)
	postMessageSystem.onMessage('syrinscape.setOneshotVolume').addListener(
		(msg: iMessage) => setOneshotVolume(msg.params.number)
	)
	postMessageSystem.onMessage('syrinscape.startElements').addListener(
		(msg: iMessage) => startElements(msg.params.pks)
	)
	postMessageSystem.onMessage('syrinscape.startMood').addListener(
		(msg: iMessage) => startMood(msg.params.pk)
	)
	postMessageSystem.onMessage('syrinscape.startPlaylistEntries').addListener(
		(msg: iMessage) => startPlaylistEntries(msg.params.pks)
	)
	postMessageSystem.onMessage('syrinscape.startSamples').addListener(
		(msg: iMessage) => startSamples(msg.params.pks)
	)
	postMessageSystem.onMessage('syrinscape.stopAll').addListener(
		(msg: iMessage) => stopAll()
	)
	postMessageSystem.onMessage('syrinscape.stopElements').addListener(
		(msg: iMessage) => stopElements(msg.params.pks)
	)
	postMessageSystem.onMessage('syrinscape.stopMood').addListener(
		(msg: iMessage) => stopMood(msg.params.pk)
	)
	postMessageSystem.onMessage('syrinscape.stopPlaylistEntries').addListener(
		(msg: iMessage) => stopPlaylistEntries(msg.params.pks)
	)
	postMessageSystem.onMessage('syrinscape.stopSamples').addListener(
		(msg: iMessage) => stopSamples(msg.params.pks)
	)

	return {
		sendFull,
		setGlobalVolume,
		setOneshotVolume,
		startElements,
		startMood,
		startPlaylistEntries,
		startSamples,
		stopAll,
		stopElements,
		stopMood,
		stopPlaylistEntries,
		stopSamples,
	}
}

export type ControlSystem = ReturnType<typeof ControlSystem>
