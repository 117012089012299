import { iAudioSystem } from 'modules-core/audioSystem'
import { Element, iElementSystem } from 'modules-core/elementSystem'
import { iSampleSystem, Sample } from 'modules-core/sampleSystem'
import { SocketAPI, SocketSystem } from 'modules-core/socket'
import { elementChunksToData, elementChunksToElementIds, elementChunksToUuids } from './utility'
import { createEvent, MathExt } from '../utility'
import getServerTime, { getServerTimeOffset, setServerTimeOffset } from 'modules-core/utility/serverTime'
import log from 'modules-core/log'
import { source } from 'common-tags'
import { secsToMillis } from 'modules-core/utility/MathExt'
import config from 'modules-core/config'

const logger = log.getLogger('syncSystem')

interface Args{
    socketSystem:SocketSystem
    elementSystem:iElementSystem
    sampleSystem:iSampleSystem
    audioSystem:iAudioSystem
}

export const SyncSystem = ({
	elementSystem,
	sampleSystem,
	socketSystem,
	audioSystem
}: Args) => {

	const events = {
		// TODO: Ask matt about createEvent type definition.
		onChangeMood: createEvent<any>(),
		onChangeSoundset: createEvent<any>(),
	}

	const updateServerTimeOffset = (timestamp) => {
		setServerTimeOffset(timestamp * 1000)
			const seconds = MathExt.millisToSecs(getServerTimeOffset())
			if (seconds > 0) {
				logger.info(`Local time is ahead of server time by ${seconds} seconds.`)
			} else if (seconds < 0) {
				logger.info(`Local time is behind of server time by ${-seconds} seconds.`)
			}
		}

	// Save canonical config delivered by server on successful registration. Clients can
	// register without session ID or token, and the server will generate values
	// accordingly which should be persisted by the client.
	const handleRegistered = (message: SocketAPI.Server.RegisteredParams) => {
		config.sessionId = message.session_id
		config.token = message.token
	}
	socketSystem.onNamedMessage('registered')
		.addListener(handleRegistered)

	const handleFullState = (state:SocketAPI.Server.PlayState)=>{
		if (getServerTimeOffset() === 0) {
			updateServerTimeOffset(state.timestamp)
		}
		handleCommonState(state, true, true)
	}
	socketSystem.onNamedMessage('send_full')
		.addListener(handleFullState)

	const handleFullElementState = (state:SocketAPI.Server.PlayState)=>{
		// Stop any currently playing chunks that belong to a specified Element, but are not
		// included in the provided state, before updating state via `handlePartialState`.
		if (getServerTimeOffset() === 0) {
			updateServerTimeOffset(state.timestamp)
		}
		const newUUids = elementChunksToUuids(state.elements)
		const newElementIds = elementChunksToElementIds(state.elements)
		getPlayingElements()
			.filter((e) => newElementIds.includes(e.elementId) && !newUUids.includes(e.uuid))
			.forEach((e) => elementSystem.stopElementByUuid(e.uuid))
		handlePartialState(state)
	}
	socketSystem.onNamedMessage('send_full_element')
		.addListener(handleFullElementState)

	const handlePartialState = (state:SocketAPI.Server.PlayState)=>{
		handleCommonState(state, false, false)
	}
	socketSystem.onNamedMessage('send_partial')
		.addListener(handlePartialState)

	const handleCommonState = (
		state:SocketAPI.Server.PlayState,
		fullElements:boolean = false,
		fullSamples:boolean = false,
	) => {
		const newElements = state.elements
		const newSamples = state.samples
		const playingElements = getPlayingElements()
		const playingSamples = getPlayingSamples()
		if (fullElements)
			handleStopElementsOnSendFull(newElements, playingElements)
		if (fullSamples)
			handleStopSamplesOnSendFull(newSamples, playingSamples)
		handlePlayElements(newElements, playingElements, fullElements)
		handlePlaySamples(newSamples, playingSamples)
		state.global_volume && audioSystem.setGlobalVolume(state.global_volume)
		state.oneshot_volume && elementSystem.setOneshotVolume(state.oneshot_volume)

		if (state.soundset_title) {
			logger.info(source`
				Current soundset:
					title: ${state.soundset_title}
					id: ${state.soundset_pk}
			`)
			events.onChangeSoundset.invoke({
				artwork: state.soundset_artwork,
				title: state.soundset_title,
				pk: state.soundset_pk,
			})
		}
		if (state.mood_title) {
			logger.info(source`
				Current mood:
					title: ${state.mood_title}
					id: ${state.mood_pk}
			`)
			events.onChangeMood.invoke({ title: state.mood_title, pk: state.mood_pk })
		}
		let spawned = 0
		let scheduled = 0
		elementSystem.forEachElement((element) => {
			spawned += element.sampleInstanceLookup.size
			scheduled += element.sampleFutureDataLookup.size
		})
		logger.debug(source`
			handleCommonState():
				active samples: ${spawned}
				scheduled samples: ${scheduled}
		`)
		if (window.Sentry) {
			const {elements, ...stateObj} = state

			window.Sentry.addBreadcrumb({
				type: "debug",
				message: "handleCommonState()",
				level: "info",
				data: {
					...stateObj,
					elements: JSON.stringify(elements),
					serverTime: state.timestamp * 1000,
					clientTime: Date.now(),
					clientTimeServerTimeDelta: Date.now() - getServerTime(),
					serverTimeOffset: Date.now() - (state.timestamp * 1000),
					getServerTimeOffset: getServerTimeOffset(),
				},
			})
		}
	}

	const handlePlayElements = (newElements:SocketAPI.Server.ElementMap, playingElements:Element.Instance[], full:boolean)=>
		elementChunksToData(newElements)
			.filter(({uuid})=>{
				const existing = playingElements.some(e=>e.uuid === uuid)
				if (existing) {
					logger.debug(source`
						handlePlayElements(): Element is already playing. This is probably a bug!
							uuid: ${uuid}
						`,
						'newElements:', newElements,
						'playingElements:', playingElements,
					)
				}
				return !existing
			})
			.sort((a, b) => (
				a.playlistData.sampleDataList[0].startTime
				> b.playlistData.sampleDataList[0].startTime
					? 1
					: -1
			))
			.forEach(data=> elementSystem.spawnElementFromData(data, full))

	const handleStopElementsOnSendFull = (newElements:SocketAPI.Server.ElementMap, playingElements:Element.Instance[])=>
		playingElements
			.filter(playingElement=>
				!elementChunksToUuids(newElements)
					.some(uuid=> uuid === playingElement.uuid))
			.forEach(({uuid})=> elementSystem.stopElementByUuid(uuid))

	const handleStopElementsByPk = ({pks}:SocketAPI.Server.StopElementsParams)=>{
		pks.forEach(pk=> elementSystem.stopElementByElementId(pk))
	}
	const handleStopPlaylistEntriesByPk = ({pks}: SocketAPI.Server.StopElementsParams) => {
		pks.forEach(pk => sampleSystem.stopPlaylistEntryByPlaylistEntryId(pk))
	}
	const handleStopSamplesByPk = ({pks}:SocketAPI.Server.StopSamplesParams) => {
		pks.forEach(pk => sampleSystem.stopDirectSampleBySampleId(pk))
	}
	const handleStopSamplesByUuid = ({uuids}:SocketAPI.Server.StopSamplesParams) => {
		uuids.forEach(uuid => sampleSystem.stopSampleBySampleUuid(uuid))
	}

	const handlePlaySamples = (newSamples: SocketAPI.Server.SampleMap, playingSamples: Sample.Instance[]) => {
		if (newSamples) {
			Object.values(newSamples).filter(({ uuid }) => {
				const existing = playingSamples.some(s=>s.uuid === uuid)
				if (existing) {
					logger.debug(source`
						handlePlaySamples(): Sample is already playing. This is probably a bug!
							uuid: ${uuid}
						`,
						'newSamples:', newSamples,
						'playingSamples:', playingSamples,
					)
				}
				return !existing
			})
			.sort((a, b) => a.start > b.start ? 1 : -1)
			.forEach((sample) => {
				const data: Sample.Data = {
					...sample,
					sampleId: sample.id,
					duration: secsToMillis(sample.end - sample.start),
					spatializable: false,
					startTime: secsToMillis(sample.start) ?? getServerTime(),
				}
				sampleSystem.spawnSampleFromData({ data })
			})
		}
	}

	const handleStopSamplesOnSendFull = (
		newSamples:SocketAPI.Server.SampleMap, playingSamples:Sample.Instance[]
	) => {
		return playingSamples
			// Get `playingSamples` that are not in `newSamples`.
			.filter((playingSample) => {
				// Sample is not part of an element instance (has no `elementId` property)
				return !Object.keys(newSamples).some(uuid => playingSample.elementId && uuid === playingSample.uuid)
			})
			.forEach(({uuid})=> {
				sampleSystem.stopSampleBySampleUuid(uuid)
			})
	}

	socketSystem.onNamedMessage('stop_elements')
		.addListener(handleStopElementsByPk)
	socketSystem.onNamedMessage('stop_playlist_entries')
		.addListener(handleStopPlaylistEntriesByPk)
	socketSystem.onNamedMessage('stop_samples')
		.addListener(handleStopSamplesByPk)
	socketSystem.onNamedMessage('stop_all')
		.addListener(()=>{
			elementSystem.stopAllElements()
			sampleSystem.stopAllSamples()
		})
	socketSystem.onNamedMessage('set_global_volume')
		.addListener(({volume}) => {
			audioSystem.setGlobalVolume(volume)
		})
	socketSystem.onNamedMessage('set_oneshot_volume')
		.addListener(({volume}) => {
			elementSystem.setOneshotVolume(volume)
		})
	socketSystem.onNamedMessage('set_element_volume')
		.addListener(({pk, volume}) => {
			elementSystem.setElementIdVolume(pk, volume)
		})

	const getPlayingElements = ()=>[...elementSystem.getElements().values()]
	const getPlayingSamples = () => {
		// Only samples that were played directly (with no associated elementId
		// or playlistEntryId).
		return [...sampleSystem.getSamples().values()].filter(
			(sample) => !sample.elementId && !sample.playlistEntryId
		)
	}
	// const extractSamples = (elements:SocketAPI.Server.Element[])=>
	// elements.map(e=> Object.values(e.chunks))


	return {
		events,
		handleFullState,
		handlePartialState
	}
}

export type SyncSystem = ReturnType<typeof SyncSystem>
