/*
Provides synchronous access to config with async write through to persistent storage.
You must call `await config.init(context = 'default')` first, then you can use the
getter/setter for each config item.
*/

import log from 'modules-core/log'
import storageDatabase from './cache/storageDatabase'
import syrinscape from 'app/common/syrinscape'
import uuid from './utility/uuid'

const logger = log.getLogger('config')

export const minVolume = 0
export const maxVolume = 1.5

const createConfig = () => {
	logger.info('createConfig()')

	// Allow overriding some config based on query string params.
	const query = new URLSearchParams(location.search)

	// Allow callers to use an existing audio context.
	let _audioContext: AudioContext

	// Set by `config.init()`. Set a different context to use a distinct set of config,
	// e.g. for use on the home page without interfering with the master interface config.
	let _context: string

	// Require `await config.init()` before access.
	let checkContext = () => {
		if (typeof _context === 'undefined') {
			throw Error(
				`Unable to access config. Call 'await config.init()' first.`
			)
		}
	}

	// Local copy of last seen value.
	let localData = {}

	// Get from local data.
	let getLocalItem = (key: string, defaultValue: any = '') => {
		checkContext()
		let value = localData[`${_context}.${key}`]
		if (typeof value === 'undefined') {
			return defaultValue
		}
		return value
	}

	// Save to local data.
	let setLocalItem = (key: string, value: any) => {
		checkContext()
		localData[`${_context}.${key}`] = value
		return value
	}

	// Get from persistent storage and write to local data.
	let getItem = async (key: string, defaultValue: any = '') => {
		checkContext()
		let promiseResolve = getSyncPromise()
		let item = await storageDatabase.getItem(`config.${_context}.${key}`)
		let value
		if (item !== null) {
			value = JSON.parse(await new Response(item).text())
		} else {
			value = defaultValue
			// Save default to persistent storage, so we can detect changes later.
			await storageDatabase.setItem(
				`config.${_context}.${key}`, new Blob([JSON.stringify(value ?? '')])
			)
		}
		promiseResolve()
		return setLocalItem(key, value)
	}

	// Save to persistent storage and write to local data.
	let setItem = async (key: string, value: any, notify: boolean = true) => {
		checkContext()
		let promiseResolve = getSyncPromise()
		let oldValue = await getItem(key)
		setLocalItem(key, value)
		const return_value = await storageDatabase.setItem(
			// Save an empty string instead of `undefined`, which will throw an error when we
			// later try to parse it as JSON.
			`config.${_context}.${key}`, new Blob([JSON.stringify(value ?? '')])
		)
		if (notify && oldValue !== value) {
			logger.info(`Config changed for key: '${key}' from: '${oldValue}' to: '${value}'`)
			document.dispatchEvent(new CustomEvent('syrinscape.updateConfig', { detail: {
				[key]: value,
			}}))
		}
		promiseResolve()
		return return_value
	}

	// Hold a promise for async operations that should block sync().
	const syncPromises: Promise<void>[] = []

	// Create a new promise that will block sync() and return a function to resolve it.
	const getSyncPromise = () => {
		let promiseResolve
		let promise = new Promise<void>((resolve) => promiseResolve = resolve)
		syncPromises.push(promise)
		return () => {
			promiseResolve()
			syncPromises.splice(syncPromises.indexOf(promise), 1)
		}
	}

	const config = {
		// @ts-ignore injected by webpack
		httpProtocol: query.get('httpProtocol') ?? SITE_PROTOCOL,

		// @ts-ignore injected by webpack
		httpHostname: query.get('httpHostname') ?? SITE_DOMAIN,

		// @ts-ignore injected by webpack
		httpPort: query.get('httpPort') ?? SITE_PORT,

		// @ts-ignore injected by webpack
		wsProtocol: query.get('wsProtocol') ?? SITE_PROTOCOL.replace('http', 'ws'),  // Infer ws or wss from http or https

		// @ts-ignore injected by webpack
		wsHostname: query.get('wsHostname') ?? SITE_DOMAIN,

		// @ts-ignore injected by webpack
		wsPort: query.get('wsPort') ?? SITE_PORT,

		// Token for secure postMessage communication between windows.
		postMessageToken: uuid(), // Disabling as it does not play nice with search

		// postMessage target window.
		postMessageWindow: window.opener ?? (
			window.parent !== window ? window.parent : null
		),

		// Populate local data from persistent storage and the server.
		init: async (context = 'default', initialConfig = {}) => {
			_context = context ?? _context ?? 'default'
			const promiseResolve = getSyncPromise()
			// Get current config, so we can later notify fields that have changed.
			const oldConfig = {
				authenticated: await getItem('authenticated'),
				displayName: await getItem('displayName'),
				sessionId: await getItem('sessionId'),
				token: await getItem('token'),
				lastLocalVolume: await getItem('lastLocalVolume', 1),
				localVolume: await getItem('localVolume', 1),
				visualisationFramerate: await getItem('visualisationFramerate', 30),
			}
			// Save initial config to persistent storage.
			await Promise.all(
				Object.entries(initialConfig).map(([key, value]) => setItem(key, value, false))
			)
			// Get server config.
			// Ideally this would use `authSystem`, but that imports `urlSystem` which
			// imports `config`, which is a circular dependency. So we manually craft an
			// authorised fetch to get a default `sessionId` from the server.
			let url = `${config.httpProtocol}://app.${config.httpHostname}/config/`
			let opts = {
				headers: {
					Accept: 'application/json',
					Authorization: `Token ${await getItem('token')}`,
				},
			}
			let response = await fetch(url, opts)
			if (response.ok) {
				let data = await response.json()
				// Get hostname, so we can connect directly and avoid being told to reconnect.
				config.wsHostname = data.hostname
				// Save server config to persistent storage.
				await setItem('authenticated', data.authenticated, false)
				await setItem('displayName', data.display_name, false)
				await setItem('sessionId', data.session_id, false)
				await setItem('token', data.token, false)
				// Get changed config.
				const changedConfig = Object.keys(oldConfig).reduce((acc, key) => {
					let value = getLocalItem(key)
					if (value !== oldConfig[key]) {
						acc[key] = value
					}
					return acc
				}, {})
				// Notify changed config.
				logger.info("Changed config:", changedConfig)
				document.dispatchEvent(
					new CustomEvent('syrinscape.updateConfig', { detail: changedConfig })
				)
			} else {
				logger.warn(`HTTP ${response.status}: Unable to get config.`)
			}
			promiseResolve()
		},

		// Wait until all sync promises are resolved or rejected.
		sync: async () => {
			await Promise.allSettled(syncPromises)
		},

		// audioContext
		get audioContext(): AudioContext {
			_audioContext ??= new AudioContext()
			return _audioContext
		},
		set audioContext(value: AudioContext) {
			if (typeof _audioContext !== 'undefined') {
				throw Error('An audio context already exists, and cannot be reassigned.')
			}
			_audioContext = value
		},

		// authenticated
		get authenticated() {
			return getLocalItem('authenticated')
		},

		// deviceName
		get deviceName() {
			logger.warn('deviceName is deprecated. Use displayName instead.')
			return config.displayName
		},

		// displayName
		get displayName() {
			return getLocalItem('displayName')
		},

		// lastLocalVolume
		get lastLocalVolume(): number {
			return getLocalItem('lastLocalVolume')
		},
		set lastLocalVolume(value: number | string) {
			value = Number(value)
			// Last local volume cannot be 0, which is the same as muted.
			if (value === 0) {
				return
			}
			if (Number.isNaN(value) || value < minVolume || value > maxVolume) {
				throw Error(`Invalid lastLocalVolume: '${value}'`)
			}
			setItem('lastLocalVolume', value)
		},

		// localVolume
		get localVolume(): number {
			return getLocalItem('localVolume')
		},
		set localVolume(value: number | string) {
			value = Number(value)
			if (Number.isNaN(value) || value < minVolume || value > maxVolume) {
				throw Error(`Invalid localVolume: '${value}'`)
			}
			setItem('localVolume', value)
		},

		// sessionId
		get sessionId() {
			return getLocalItem('sessionId')
		},
		set sessionId(value: string) {
			setItem('sessionId', value)
		},

		// token
		get token() {
			return getLocalItem('token')
		},
		set token(value: string) {
			if (value !== config.token) {
				// Re-init to update `authenticated` and `displayName` as well.
				config.init(_context, { token: value })
			}
		},

		// visualisationFramerate
		get visualisationFramerate(): number {
			return getLocalItem('visualisationFramerate')
		},
		set visualisationFramerate(value: number | string) {
			value = Number(value)
			if (Number.isNaN(value) || value <= 0) {
				throw Error(`Invalid visualisationFramerate: ${value}`)
			}
			setItem('visualisationFramerate', value)
		},
	}

	return config
}

syrinscape.config ??= createConfig()

export const config = syrinscape.config
export default config
