
import { createEvent, wait } from 'modules-core/utility'
import { defaultEffectFileUrl } from '.'
import { createDefaultEffectFile } from './defaultEffectFile'
import { createEffectMap } from './effects/createEffectMap'
import { AudioEffect } from './types'
import config from 'modules-core/config'
import getServerTime from 'modules-core/utility/serverTime'

export const createAudioEffectSystem = () => {
	const effectMap: AudioEffect.EffectMap = {}
	let file: AudioEffect.EffectFile = null

	const system = {
		onFileChange: createEvent<AudioEffect.EffectFile>(),
		init: async (useDefault = true) => {
			if(useDefault)
				await system.loadFile(createDefaultEffectFile())
			else
				await system.loadFileFromUrl(defaultEffectFileUrl)
		},
		loadFile: async (_file: AudioEffect.EffectFile) => {
			file = _file
			await system.rebuildAllEffects(file.effects)
		},
		loadFileFromUrl: async (url: string) => {
			const response = await fetch(url)
			const json = await response.json()
			await system.loadFile(json)
		},
		get analyser() { return effectMap['Analyser'] as AudioEffect.AnalyserEffect },
		get defaultPreset() { return file.presets['Default'] },
		get globalVolume() { return effectMap['Global Volume'] as AudioEffect.VolumeEffect },
		get localVolume() { return effectMap['Local Volume'] as AudioEffect.VolumeEffect },
		preset: (preset: AudioEffect.Preset) =>
			file.presets[preset],
		effect: <T extends AudioEffect.Effect>(name: string) =>
			effectMap[name] as T,
		updatePreset: (params: AudioEffect.PresetParams) => {
			file.presets[params.name] = params
			system.updateFile()
		},
		rebuildAllEffects: async (effects: AudioEffect.EffectParams[]) => {
			system.disconnectAll()
			await Promise.all(effects.map(system.buildEffect))
			system.reconnectAll()
		},
		buildEffect: async (params: AudioEffect.EffectParams) => {
			effectMap[params.name]?.disconnectAll()
			const effect = await createEffectMap[params.type](params)
			effectMap[params.name] = effect
		},
		buildEffectAndReconnectAll: async (params: AudioEffect.EffectParams) => {
			await system.buildEffect(params)
			system.reconnectAll()
		},
		connectEffect: (effect: AudioEffect.Effect) => {
			if (effect.params.output) {
				if (!effectMap[effect.params.output])
					throw new Error(`Audio effect not found: ${effect.params.output}`)
				effect.connect(effectMap[effect.params.output])
				effect.connectFromMap?.(effectMap)
			}
			else//only for master
				effect.output.connect(config.audioContext.destination)
		},
		reconnectAll: () => {
			Object.values(effectMap)
				.forEach(system.connectEffect)
			system.updateFile()
		},
		trySetParam: (effectName: string, paramName: string, value: any) => {
			if (effectMap[effectName]?.setParam[paramName]) {
				effectMap[effectName].setParam[paramName](value)
				file = setFileEffectParam(file, effectName, paramName, value)
				file.timestamp = getServerTime()
				return true
			}
			return false
		},
		updateFile: () => {
			file.effects = Object.values(effectMap).map(e => e.params)
			file.effects.sort((a, b) => a.name > b.name ? 1 : -1)
			file.timestamp = getServerTime()
			// if(!quiet)
			system.onFileChange.invoke(file)
		},
		disconnectAll: () => {
			Object.keys(effectMap)
				.forEach(system.disposeEffect)
		},
		disposeEffect: (name: string) => {
			effectMap[name].disconnectAll()
			delete effectMap[name]
			// effectMap[name] = undefined
		},
		disposeEffectAndReconnectAll: (name: string) => {
			system.disposeEffect(name)
			system.reconnectAll()
		},
	}
	return system
}


export const setFileEffectParam = (file: AudioEffect.EffectFile, effectName: string, paramName: string, paramValue: any) => {
	//this would be a one-liner if we used maps instead of arrays
	const next = { ...file, effects: [...file.effects] }
	const eff = next.effects.find(e => e.name === effectName)
	const index = next.effects.indexOf(eff)
	next.effects[index] = { ...eff, [paramName]: paramValue }
	return next
}

export type AudioEffectSystem = ReturnType<typeof createAudioEffectSystem>
