import { AudioEffect } from "modules-core/audioEffectSystem"
import * as d3 from "d3"
import $ from "jquery"
import config from "modules-core/config"
import log from "modules-core/log"
import syrinscape from "app/common/syrinscape"
import { eventSystem } from "./eventSystem"

// Register events.
eventSystem.add('stopVisualisation')
eventSystem.add('startVisualisation')

const logger = log.getLogger("visualisation")

export type Visualiser = () => boolean

const elementStateMap = new WeakMap<HTMLElement, any>()

// Keep a state object for data related to an element (identified by selector) in a weak
// map, so we can do things like setup on first call and re-do setup if the element is
// recreated.
const getElementStateObject = (selector) => {
	const element = $(selector)[0]
	if (element === undefined) {
		return undefined
	}
	if (!elementStateMap.has(element)) {
		elementStateMap.set(element, {})
	}
	return elementStateMap.get(element)
}

export function d3VisualiseFrequencyData(
	data: AudioEffect.FrequencyData, selector: string
) {
	// Do not visualise high frequencies, which are almost always empty.
	const binCount = Math.ceil(data.frequencyBinCount * 0.7)
	// Get state object.
	const state = getElementStateObject(selector)
	// Bailout early.
	if (state === undefined) {
		return
	}
	// Do setup on first draw.
	if (!state.setupDone) {
		// Configure.
		state.setupDone = true
		state.colorScale = d3
			.scaleSequential(d3.interpolateCool)
			.domain([0, binCount - 1]) // -1 because zero-indexed
		state.element = d3.select(selector)
		state.xScale = d3
			.scaleLinear()
			.range([0, 100])
			.domain([0, binCount - 1]) // -1 because zero-indexed
		state.yScale = d3.scaleLinear().range([0, 100]).domain([0, 255]) // 0-255 because https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/getByteFrequencyData
		// Initial draw.
		state.element
			.selectAll("rect")
			.data(data.frequencyData.slice(0, binCount))
			.enter()
			.append("rect")
			.attr("stroke", (datum, index) =>
				// Use same color scale in reverse for borders.
				state.colorScale(binCount - index)
			)
			.attr("stroke-width", "1px")
			.attr("fill", (datum, index) => state.colorScale(index))
			.attr("width", (datum, index) => `${state.xScale(1) - state.xScale(0)}%`)
			.attr("x", (datum, index) => `${state.xScale(index)}%`)
			.attr("y", 0)
	}
	// Update draw.
	else {
		state.element
			.selectAll("rect")
			.data(data.frequencyData.slice(0, binCount))
			.attr("height", (datum, index) => `${state.yScale(datum)}%`)
	}
}

export function d3VisualiseWaveformData(
	data: AudioEffect.TimeDomainData, selector: string
) {
	// Get state object.
	const state = getElementStateObject(selector)
	// Bailout early.
	if (state === undefined) {
		return
	}
	// Do setup on first draw.
	if (!state.setupDone) {
		// Config.
		state.setupDone = true
		state.element = d3.select(selector)
		state.xScale = d3
			.scaleLinear()
			.range([0, 200])
			.domain([0, data.fftSize - 1]) // -1 because zero-indexed
		state.yScale = d3.scaleLinear().range([0, 100]).domain([-1, 1]) // getFloatTimeDomainData returns values between -1 and 1
		state.line = d3
			.line()
			.x((datum, index) => state.xScale(index))
			.y((datum, index) => state.yScale(datum))
		// Initial draw.
		state.element
			.attr("preserveAspectRatio", "none") // Scale to fill container
			.attr("viewBox", "0 0 200 100") // Treat path coordinates as percentage values
			.append("path")
			.datum(data.timeDomainData)
			.attr("d", state.line)
			.attr("fill", "none")
			.attr("stroke", "rgba(239, 239, 239, 1)")
			.attr("stroke-width", "0.25px")
	}
	// Update draw.
	else {
		state.element
			.select("path")
			.datum(data.timeDomainData)
			.attr("d", state.line)
	}
}

export function createVisualisation() {
	logger.info('createVisualisation()')

	let _isActive: boolean = false
	let requestId: number
	let visualiserMap = new Map<string, Visualiser>()

	let nextTime = 0

	const update = (currentTime) => {
		// Nothing to do.
		if (currentTime < nextTime) {
			requestId = requestAnimationFrame(update)
			return
		}
		// Render.
		let anyActive: boolean = false
		visualiserMap.forEach((visualiser) => anyActive = visualiser() || anyActive)
		if (_isActive || anyActive) {
			nextTime = currentTime + 1000 / config.visualisationFramerate
			requestId = requestAnimationFrame(update)
		} else {
			logger.info('Pause visualisation. No activity during update.')
			eventSystem.dispatch('stopVisualisation')
		}
	}

	return {
		get isActive() {
			return _isActive
		},
		add(name: string, visualiser: Visualiser) {
			visualiserMap.set(name, visualiser)
		},
		d3,
		d3VisualiseFrequencyData,
		d3VisualiseWaveformData,
		init() {
			// Draw a single frame to execute setup for each configured visualiser.
			requestAnimationFrame(update)
		},
		remove(name: string) {
			visualiserMap.delete(name)
		},
		start() {
			if (!_isActive) {
				logger.info('Start visualisation.')
				_isActive = true
				eventSystem.dispatch('startVisualisation')
				requestAnimationFrame(update)
			}
		},
		stop() {
			if (_isActive) {
				logger.info('Stop visualisation on next update with no activity.')
				_isActive = false
			}
		},
		sumData(data: Partial<AudioEffect.AnalyserData>[]) {
			return data.reduce((previous, current) => {
				if (current.frequencyData) {
					for (let i = 0; i < current.frequencyBinCount; i++) {
						current.frequencyData[i] = Math.min(
							255, current.frequencyData[i] + previous.frequencyData[i]
						)
					}
				}
				if (current.timeDomainData) {
					for (let i = 0; i < current.fftSize; i++) {
						current.timeDomainData[i] = Math.min(
							1, Math.max(-1, current.timeDomainData[i] + previous.timeDomainData[i])
						)
					}
				}
				return current
			})
		},
	}
}

syrinscape.visualisation ??= createVisualisation()

export const visualisation = syrinscape.visualisation
export default visualisation
