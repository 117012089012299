import localforage from 'localforage'
import { iDatabase } from './types'
import { cleanKey } from './utility'

export const createLocalForageDatabase = (name = 'database'): iDatabase => {
	const forage = localforage.createInstance({
		name,
		driver: localforage.INDEXEDDB,
		version: 1.0,
	})

	// localForage passes error strings to a callback, instead of throwing them directly.
	const errCallback = (err) => {
		if (err) {
			throw new Error(`localForage error: ${err}`)
		}
	}

	const db: iDatabase = {
		getItem: async (key) => await forage.getItem(cleanKey(key), errCallback),
		setItem: async (key, val) => await forage.setItem(cleanKey(key), val, errCallback),
		removeItem: async (key) => await forage.removeItem(cleanKey(key), errCallback),
		clear: forage.clear,
		getKeys: forage.keys,
	}
	return db
}
