import { createEvent, MathExt } from 'modules-core/utility'
import { Sample } from 'modules-core/sampleSystem'
import { createSeededRandom, fromLength, randomIndex, wrapReorder } from 'modules-core/utility/MathExt'
import { Element } from './types'



export const RANDOM_PLAYLIST_LENGTH = 12

interface Args {
}
export const createPlaylistSystem = ({ }: Args = {}) => {

	const playedSamplesMap: Element.PlayedSamplesMap = new Map()

	const getOrCreatePlayedSamples = (elementId: number) => {
		if (playedSamplesMap.has(elementId))
			return playedSamplesMap.get(elementId)
		const playedSamples: number[] = []
		playedSamplesMap.set(elementId, playedSamples)
		return playedSamples
	}

	const system = {
		onPlayedSamplesChanged: createEvent<Element.PlayedSamplesMap>(),
		playedSamplesMap,
		createPlaylist: (elementParams: Element.Params) => {
			let { playedSamples } = elementParams
			const { elementId, playlistParams, type, randomSeed } = elementParams
			const { sampleParamsList, order } = playlistParams

			if (sampleParamsList.length <= 1)
				return sampleParamsList

			const randomFunc = randomSeed === undefined
				? Math.random
				: createSeededRandom(randomSeed)

			if (order === 'random')
				return fromLength<Sample.Params>(
					() => randomIndex(sampleParamsList, randomFunc)
					, RANDOM_PLAYLIST_LENGTH)

			playedSamples ??= getOrCreatePlayedSamples(elementId)
			if (playedSamples.length >= sampleParamsList.length) {
				const last = playedSamples[playedSamples.length - 1]
				system.clearPlayedSamples(elementId)
				system.appendPlayedSamples(elementId, last)
			}

			let newPlaylist = [...sampleParamsList]

			const lastPlayed = playedSamples.length > 0
				? playedSamples[playedSamples.length - 1]
				: undefined

			if (order === 'shuffle') {
				MathExt.shuffleArray(newPlaylist, randomFunc)
				if (newPlaylist[0].sampleId === lastPlayed)
					newPlaylist.push(newPlaylist.shift())
			} else {
				if (lastPlayed) {
					const nextIndex = (newPlaylist.indexOf(
						newPlaylist.find(sample =>
							sample.sampleId === lastPlayed))
						+ 1) % newPlaylist.length
					newPlaylist = wrapReorder(newPlaylist, nextIndex)
				}
			}

			if (type === 'oneshot') {
				newPlaylist = newPlaylist
					.filter(sample => !playedSamples.includes(sample.sampleId))
				newPlaylist.splice(1)
				system.appendPlayedSamples(elementId, newPlaylist[0].sampleId)
			}

			return newPlaylist
		},
		clearPlayedSamples: (elementId: number) => {
			getOrCreatePlayedSamples(elementId)
				.length = 0
			system.onPlayedSamplesChanged
				.invoke(playedSamplesMap)
		},
		clearAllPlayedSamples: () => {
			playedSamplesMap.clear()
			system.onPlayedSamplesChanged
				.invoke(playedSamplesMap)
		},
		appendPlayedSamples: (elementId: number, sampleId: number) => {
			const playedSamples = getOrCreatePlayedSamples(elementId)
			// playedSamples.length = 0
			if (!playedSamples.includes(sampleId))
				playedSamples.push(sampleId)
			system.onPlayedSamplesChanged
				.invoke(playedSamplesMap)
		}
	}
	return system
}
