
export interface iVector2 {
	x: number
	y: number
}
export interface iVector3 {
	x: number
	y: number
	z: number
}


// Add one vector to another.
export const add = (v1: iVector3, v2: iVector3) => ({
	x: v1.x + v2.x,
	y: v1.y + v2.y,
	z: v1.z + v2.z
})

export const length = (v: iVector3) =>
	Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z)

export const multiply = (v: iVector3, val: number) => ({
	x: v.x * val,
	y: v.y * val,
	z: v.z * val
})

export const normalize = (v: iVector3) =>
	multiply(v, 1 / length(v))


