
export const createUniqueArray = <T>() => {

	const items: T[] = []

	return {
		_items: items,
		addItem: (item: T) => {
			const index = items.indexOf(item)
			if (index === -1) {
				items.push(item)
				return true
			}
			return false
		},
		removeItem: (item: T) => {
			const index = items.indexOf(item)
			if (index > -1) {
				items.splice(index, 1)
				return true
			}
			return false
		},
		clearItems: () => items.length = 0,
		getItems: () => items
	}
}


