// @ts-ignore injected by webpack
const mediaPrefix = MEDIA_PREFIX

const prefix = 'http://browser-storage.syrinscape.com/'
const cleanedMediaURL = new URL(prefix)
cleanedMediaURL.hostname = 'browser-storage-media.syrinscape.com'
const cleanedMediaPrefix = cleanedMediaURL.toString()

export const cleanKey = (key: string) => {
	// Convert key to a URL suitable for `Request()`, which the Cache API uses as keys.
	try {
		let url = new URL(key)
		// Strip the querystring from S3 URLs, because these are always (usually?) for
		// authorisation and do not identify a distinct object. Including these in the key
		// would result in many accidental `getItem()` failures when the same distinct
		// object is requested.
		if (key.startsWith(mediaPrefix)) {
			url.protocol = cleanedMediaURL.protocol
			url.port = cleanedMediaURL.port
			url.hostname = cleanedMediaURL.hostname
			url.search = ''
			key = url.toString()
		}
	} catch (_) {
		// When the key is not already a URL with a hostname, convert it to a bogus URL with
		// a consistent scheme and hostname. Otherwise, when passed to `Request()` it will
		// implicitly use the current hostname, which might result in an unintentional
		// `getItem()` failure if accessed via different hostnames (e.g. `app.*` or `sN.`)
		key = `${prefix}${encodeURIComponent(key)}`
	}
	return key
}

export const unCleanKey = (key: string) => {
	// Convert a URL key from the database back to its original string.
	if (key.startsWith(prefix)) {
		key = decodeURIComponent(key.substring(prefix.length))
	} else if (key.startsWith(cleanedMediaPrefix)) {
		key = decodeURIComponent(key.substring(cleanedMediaPrefix.length))
		key = `${mediaPrefix}${key}`
	}
	return key
}
