export class LinkedListNode<K, V> {
	data: V
	id: K
	next: LinkedListNode<K, V> | null
	prev: LinkedListNode<K, V> | null

	constructor(id: K, data: any) {
		this.data = data
		this.id = id
		this.next = null
		this.prev = null
	}
}

export class LRULinkedList<K, V> {
	_size: number = 0

	head: LinkedListNode<K, V> | null
	tail: LinkedListNode<K, V> | null
	limit: number
	positions: Map<K, LinkedListNode<K, V>> = new Map<K, any>()

	constructor(limit: number = Infinity) {
		this.head = null
		this.tail = null
		this.limit = limit
	}

	get size() {
		return this._size
	}

	set size(size: number) {
		this._size = size

		if (this._size > this.limit) {
			this.removeExcessNodes()
		}
	}

	get(id: K): V | null {
		const node = this.positions.get(id)

		if (node) {
			const newHeadNode = this.moveToHead(node)

			if (newHeadNode) {
				return newHeadNode.data
			}

			return null
		}

		return null
	}

	remove(id: K): LinkedListNode<K, V> | null {
		if (!this.head) {
			return null
		}

		const node = this.positions.get(id)

		if (!node) {
			return null
		}

		const prevNode = node.prev
		const nextNode = node.next

		// both prev and next node exists i.e. this node is neither head nor tail node
		if (prevNode && nextNode) {
			prevNode.next = nextNode
			nextNode.prev = prevNode
			this.positions.delete(id)
			this.size--

			return node
		}

		// if this is the tail node
		if (prevNode && !nextNode) {
			prevNode.next = null
			this.tail = prevNode
			this.positions.delete(id)
			this.size--

			return node
		}

		// if this is the head node
		if (!prevNode && nextNode) {
			nextNode.prev = null
			this.head = nextNode
			this.positions.delete(id)
			this.size--

			return node
		}

		// if this is both the head and the tail i.e. the only node
		if (!prevNode && !nextNode) {
			this.head = null
			this.tail = null
			this.positions.delete(id)
			this.size--

			return node
		}

		return null
	}

	has(id: K): boolean {
		if (this.positions.has(id)) {
			return true
		}

		return false
	}

	keys(): K[] {
		return Array.from(this.positions.keys())
	}

	moveToHead(node: LinkedListNode<K, V>): LinkedListNode<K, V> | null {
		const nodeToMove = this.remove(node.id)

		if (nodeToMove) {
			const newNode = this.addNodeToHead(nodeToMove.id, nodeToMove.data)

			return newNode
		}

		return null
	}

	addNodeToHead(id: K, data: V): LinkedListNode<K, V> {
		if (this.positions.has(id)) {
			const node = this.moveToHead(this.positions.get(id))

			return node
		}

		if (this.head) {
			let oldHead = this.head
			const newHead = new LinkedListNode<K, V>(id, data)

			newHead.next = oldHead
			newHead.prev = null
			oldHead.prev = newHead
			this.head = newHead
			this.positions.set(id, newHead)
			this.size++

			return newHead
		} else {
			const newNode = new LinkedListNode<K, V>(id, data)
			this.head = newNode

			if (!this.tail) {
				this.tail = newNode
			}

			this.positions.set(id, newNode)
			this.size++

			return newNode
		}
	}

	// remove excess nodes from tail until size === limit
	removeExcessNodes() {
		const nodesToRemove = this.size - this.limit

		this.removeNodes(nodesToRemove)
	}

	// remove given number of nodes from tail
	removeNodes(nodesToRemove = 1) {
		for (let i = 0; i < nodesToRemove; i++) {
			this.removeTailNode()
		}
	}

	removeTailNode() {
		if (!this.tail) {
			return null
		}

		let tailNode = this.tail
		let currentNode = this.tail

		this.positions.delete(currentNode.id)
		this.size--
		currentNode = currentNode.prev

		if (currentNode) {
			currentNode.next = null
		} else {
			this.head = null
		}
		this.tail = currentNode

		return tailNode
	}
}
