import { createAnalyserEffect } from './analyserEffect'
import { createCompressorEffect } from './compressorEffect'
import { createFilterEffect } from './filterEffect'
import { AudioEffect } from '../types'
import { createConvolverEffect } from './convolverEffect'
import { createDelayEffect } from './delayEffect'
import { createSendEffect } from './sendEffect'
import { createSpatialEffect } from './spatialEffect'
import { createVolumeEffect } from './volumeEffect'
import { createReverbEffect } from './reverbEffect'
import { createFreeverbEffect } from './freeverbEffect'


export const createEffectMap: AudioEffect.CreateEffectMap = {
	'volume': createVolumeEffect,
	'analyser': createAnalyserEffect,
	'compressor': createCompressorEffect,
	'convolver': createConvolverEffect,
	'send': createSendEffect,
	'delay': createDelayEffect,
	'spatial': createSpatialEffect,
	'filter': createFilterEffect,
	'reverb': createReverbEffect,
	'freeverb': createFreeverbEffect
}