import { createEvent, MathExt, add, length, multiply } from 'modules-core/utility'
import { createSendEffect, rampToCenteredVelocityOverTime } from 'modules-core/audioEffectSystem'
import { createSpatialEffect } from 'modules-core/audioEffectSystem/effects/spatialEffect'
import { AudioEffect } from 'modules-core/audioEffectSystem/types'
import { FadeOptions, iAudioSystem } from 'modules-core/audioSystem'
import { Sample } from 'modules-core/sampleSystem/types'
import { createUuidSystem, iUuidSystem } from 'modules-core/uuidSystem'
import log from 'modules-core/log'
import getServerTime, { getServerTimeOffset } from 'modules-core/utility/serverTime'
import { source } from 'common-tags'
import { eventSystem } from 'modules-core/eventSystem'
import { clearStrictTimeout, setStrictTimeout } from 'modules-core/utility/strictTimeout'
import { filterBySampleId } from 'modules-core/elementSystem'
import config from 'modules-core/config'
import syrinscape from 'app/common/syrinscape'

const logger = log.getLogger('sampleSpawnSystem')

// Register events.
eventSystem.add('startSample')

interface iSpawnSample {
	wetOut?: AudioEffect.HasInput
	dryOut?: AudioEffect.HasInput
}

interface iSpawnSampleFromParamsArgs extends iSpawnSample {
	params: Sample.Params
	startTime?: number
}

interface iSpawnSampleFromDataArgs extends iSpawnSample {
	data: Sample.Data
	fadeOptions?: FadeOptions
}

interface iArgs {
	audioSystem: iAudioSystem
	uuidSystem?: iUuidSystem
	sampleMap: Sample.MutableMap
}
export const createSampleSpawnSystem = ({ audioSystem, uuidSystem, sampleMap }: iArgs) => {
	const { effectSystem } = audioSystem
	uuidSystem = uuidSystem ?? createUuidSystem()

	const system = {
		spawnSampleFromParams: ({ params, startTime, wetOut, dryOut }: iSpawnSampleFromParamsArgs) => {
			const data: Sample.Data = {
				...params,
				volume: MathExt.randomBetween(params.minGain, params.maxGain),
				uuid: uuidSystem.nextId(),
				startTime: startTime ?? getServerTime(),
			}
			// eventSystem.dispatch('startSample', data)
			return system.spawnSampleFromData({ data, wetOut, dryOut })
		},
		spawnSampleFromData: ({ data, wetOut, dryOut, fadeOptions }: iSpawnSampleFromDataArgs) => {
			const preset = effectSystem.preset(data.presetName)
			wetOut ??= effectSystem.effect(preset?.effect)
			if (data.presetName === 'Off')
				wetOut = undefined
			dryOut ??= effectSystem.globalVolume
			const { spatialData, volume, startTime } = data
			data.uuid ??= uuidSystem.nextId()

			let wetSpatial: AudioEffect.SpatialEffect
			let drySpatial: AudioEffect.SpatialEffect
			let wetDrySend: AudioEffect.SendEffect

			//create dry spatializer
			if (spatialData) {
				const params = preset?.drySpatializer ?? effectSystem.defaultPreset.drySpatializer
				drySpatial = createSpatialEffect(params)
				drySpatial.connect(dryOut)
				dryOut = drySpatial
			}

			//create wet spatializer
			if (spatialData && wetOut) {
				const params = preset?.wetSpatializer ?? effectSystem.defaultPreset.wetSpatializer
				wetSpatial = createSpatialEffect(params)
				wetSpatial.connect(wetOut)
				// Position wet spatialiser directly in front of the listener at a distance
				// equal to the initial sample position.
				wetSpatial.setPosition({ x: 0, y: 0, z: length(spatialData.position) })
				wetOut = wetSpatial
			}

			let sourceOut = dryOut

			//create wet dry send
			if (wetOut) {
				const wetDrySend = createSendEffect()
				wetDrySend._wet.connect(wetOut.input)
				wetDrySend._dry.connect(dryOut.input)
				const wetGain = preset?.wetGain ?? effectSystem.defaultPreset.wetGain
				const dryGain = preset?.dryGain ?? effectSystem.defaultPreset.dryGain
				wetDrySend._wet.gain.value = wetGain
				wetDrySend._dry.gain.value = dryGain
				sourceOut = wetDrySend
			}

			const audioSource = audioSystem.createAudioSource(data.url, sourceOut, fadeOptions, volume)

			let startHandle

			const sampleInstance: Sample.Instance = {
				...data,
				alive: true,
				isPlaying: false,
				audioSource,
				onDispose: createEvent(),
				dispose: async () => {
					if (!sampleInstance.alive) {
						// Failsafe in case the recursive chain between `sampleInstance` and
						// `audioSource` is not broken.
						logger.warn(source`
							sampleInstance.dispose(): Already dead! Can only dispose once!
								name: ${sampleInstance.name}
								uuid: ${sampleInstance.uuid}
								src: ${data.url}
						`)
						return
					}
					logger.debug(source`
						sampleInstance.dispose():
							name: ${sampleInstance.name}
							uuid: ${sampleInstance.uuid}
							src: ${data.url}
					`)
					clearStrictTimeout(startHandle)
					sampleInstance.alive = false
					// Break the recursive chain between `sampleInstance` and `audioSource`.
					audioSource.onDispose.removeListener(sampleInstance.dispose)
					if (audioSource.isAlive) {
						// Emit first `stopSample` event on request to stop, before fade out.
						const stopTime = sampleInstance.startTime + sampleInstance.duration
						let now = getServerTime()
						eventSystem.dispatch('stopSample', {
							elementId: sampleInstance.elementId,
							playlistEntryId: sampleInstance.playlistEntryId,
							sampleId: sampleInstance.sampleId,
							timeToStop: Math.min(Math.max(stopTime - now, 0), 3000) || 0,
						})
						// Fade out.
						await audioSource.stop()
						// Emit final `stopSample` event if there are no other active samples with
						// the same ID. This can happen when a sample is restarted during the fade
						// out after being stopped.
						now = getServerTime()
						if (!filterBySampleId(sampleMap, sampleInstance.sampleId).some(
							([, sample]) =>
								sample.uuid !== sampleInstance.uuid
								&& sample.startTime <= now
								&& now < sample.startTime + sample.duration
								// Only compare playlist entry samples with playlist entry samples, and
								// library samples with library samples. We must still emit `stopSample`
								// when a playlist entry sample is disposed even if the same sample is
								// also still active via the library.
								&& sample.elementId === sampleInstance.elementId
								&& sample.playlistEntryId === sampleInstance.playlistEntryId
						)) {
							eventSystem.dispatch('stopSample', {
								elementId: sampleInstance.elementId,
								playlistEntryId: sampleInstance.playlistEntryId,
								sampleId: sampleInstance.sampleId,
								timeToStop: 0, // Already stopped
							})
						}
					}
					wetSpatial?.disconnectAll()
					drySpatial?.disconnectAll()
					wetDrySend?.disconnectAll()
					sampleMap.mutate(map => map.delete(data.uuid))
					// Stop visualisation when there are no samples.
					if (sampleMap.getRaw().size === 0) {
						syrinscape.visualisation?.stop()
					}
					sampleInstance.onDispose.invoke()
				}
			}

			// Dispose of this sample after its audio source is disposed (e.g. when it ends).
			// This is required for oneshots which are never explicitly stopped.
			audioSource.onDispose.addListener(sampleInstance.dispose)

			const startSample = (scrubToSecs?: number) => {
				logger.debug(source`
					startSample():
						name: ${sampleInstance.name}
						uuid: ${sampleInstance.uuid}
						src: ${data.url}
				`)

				if (window.Sentry) {
					const now = getServerTime()

					window.Sentry.addBreadcrumb({
						type: "debug",
						message: "startSample()",
						level: "info",
						data: {
							...data,
							clientTime: Date.now(),
							endTime: data.startTime + data.duration,
							getServerTime: now,
							startTimeServerTimeDelta: data.startTime - now,
							clientTimeServerTimeDelta: Date.now() - now,
							getServerTimeOffset: getServerTimeOffset(),
						},
					})
				}

				if (spatialData) {
					let duration = data.duration / 1000
					// Set the original position and use the original (full) panning time.

					let startPos = spatialData.position
					const { velocity } = spatialData
					if (scrubToSecs !== undefined) {
						// Set a new starting position (original + velocity over scrubbed seconds) and
						// reduce the panning time by scrubbed seconds.
						startPos = add(startPos, multiply(velocity, scrubToSecs))
						duration -= scrubToSecs
					}
					drySpatial.setPosition(startPos)
					drySpatial.setVelocity(velocity, duration)

					if (wetSpatial !== undefined) {
						const now = config.audioContext.currentTime
						rampToCenteredVelocityOverTime(wetSpatial.node.positionZ, startPos, velocity, now, duration)
					}
				}
				// Start visualisation with samples.
				syrinscape.visualisation?.start()
				audioSource.play(scrubToSecs)
				sampleInstance.isPlaying = true
				sampleMap.update()

				if (data.elementId && !data.manuallyTriggered) {
					// For playlist entry samples triggered via the element (not manually), we
					// need access to the element system to send `timeToNextSample`. Dispatch
					// internal `startElementSample` event to avoid circular import.
					document.dispatchEvent(
						new CustomEvent('syrinscape.internalStartElementSample', { detail: data })
					)
				} else {
					eventSystem.dispatch('startSample', {
						elementId: data.elementId,
						playlistEntryId: data.playlistEntryId,
						sampleId: data.sampleId,
						timeToStop: data.startTime + data.duration - getServerTime(),
					})
				}
			}

			const now = getServerTime()

			if (window.Sentry) {
				window.Sentry.addBreadcrumb({
					type: "debug",
					message: "spawnSampleFromData()",
					level: "info",
					data: {
						...data,
						clientTime: Date.now(),
						endTime: data.startTime + data.duration,
						getServerTime: now,
						startTimeServerTimeDelta: data.startTime - now,
						clientTimeServerTimeDelta: Date.now() - now,
						getServerTimeOffset: getServerTimeOffset(),
					},
				})
			}

			// Scrub to current position and start if start time is more than 5 seconds ago.
			if (startTime <= now - 5000) {
				logger.debug(source`
					spawnSampleFromData(): Already in progress. Scrub ${MathExt.millisToSecs((now - startTime))} seconds.
						name: ${data.name}
						uuid: ${data.uuid}
				`)
				startSample(MathExt.millisToSecs(now - startTime))
			}
			// Start with no scrubbing if start time is within 5 seconds.
			else if (startTime <= now) {
				logger.debug(source`
					spawnSampleFromData(): Start with no scrubbing.
						name: ${data.name}
						uuid: ${data.uuid}
				`)
				startSample()
			}
			// Schedule start if start time is in the future.
			else {
				startHandle = setStrictTimeout(startSample, startTime - now, data.duration)
				logger.debug(source`
					spawnSampleFromData(): Schedule start in ${MathExt.millisToSecs((startTime - now))} seconds.
						name: ${data.name}
						uuid: ${data.uuid}
						startHandle: ${startHandle}
				`)
			}

			sampleMap.mutate(map =>
				map.set(data.uuid, sampleInstance))
			return sampleInstance
		}
	}
	return system
}

export type iSampleSpawnSystem = ReturnType<typeof createSampleSpawnSystem>
